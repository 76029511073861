import React from "react";
import Header from "components/Headers/Header";
import List from "./List";
import Container from "reactstrap/lib/Container";

const Performance = () => {
  return (
    <>
      <Header />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Container className="mt--9" fluid>
        <List />
      </Container>
    </>
  );
};

export default Performance;
