import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

// import Categories from "../../views/examples/";
import { getMenu } from "../../store/actions/menuActions";
import { getRestaurant, getUser } from "../../store/actions/userActions";
import Graphs from "../../views/examples/graphs/Graphs";

class DashboardHeader extends React.Component {
  componentDidMount() {
    var { getUser, uid, getMenu, getRestaurant, user } = this.props;
    getUser(uid);
    getMenu(user.restaurant);
    getRestaurant(user.restaurant);
  }
  render() {
    var { menu } = this.props;
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="6">
                  <Link to="/admin/menu">
                    <Card className="card-stats mb-6 mb-xl-6">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Menu Items
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {menu && menu.length}{" "}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div
                              className="icon icon-shape text-white rounded-circle shadow"
                              style={{
                                background: "#E8C85E",
                              }}
                            >
                              <i className="fas fa-building text-dark" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Graphs graphType="loyaltyVisits" />
      </>
    );
  }
}

export function mapStateToProps(state) {
  return {
    uid: state.auth.uid,
    user: state.auth.user,
    menu: state.auth.menu,
    loading: state.auth.requested,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    getUser: (user) => dispatch(getUser(user)),
    getMenu: (user) => dispatch(getMenu(user)),
    getRestaurant: (user) => dispatch(getRestaurant(user)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
