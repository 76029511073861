import React, { useEffect, useState } from "react";
import axios from "axios";

import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const PREFIX = "RestaurantChargingsGraph";

const classes = {
  backdrop: `${PREFIX}-backdrop`,
};

const Root = styled("div")(({ theme: { palette, ...theme } }) => ({
  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 500,
    color: "pink",
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "Days of the Month",
      },
    },
    y: {
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "Chargings",
      },
    },
  },
  ticks: {
    precision: 0,
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chargings",
    },
  },
};

export default function RestaurantChargingsGraph() {
  const [loader, setLoader] = useState(false);
  const [graphsData, setGraphsData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoader(true);
    axios
      .post(
        `https://us-central1-new-practice-6441a.cloudfunctions.net/getGraphDataForRestaurantCharging`,
        {
          restaurantName: localStorage.getItem("restaurant_id"),
        }
      )
      .then((res) => {
        setLoader(false);

        let labels = res.data.currentMonthChargings.map((el) => {
          if (el.date * 1 === 1) {
            return `${el.date * 1}st`;
          }
          if (el.date * 1 === 2) {
            return `${el.date * 1}nd`;
          }
          if (el.date * 1 === 3) {
            return `${el.date * 1}rd`;
          } else {
            return `${el.date * 1}th`;
          }
        });

        const data = {
          labels,
          datasets: [
            {
              label: "Restaurant's Chargings",
              data: res.data.currentMonthChargings.map((el) => {
                return el.chargings;
              }),
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        };

        setGraphsData(data);
      })
      .catch((error) => {
        setLoader(false);
        console.log("Got error");
        console.log(error);
      });
  };

  return (
    <Root>
      {graphsData && <Line options={options} data={graphsData} />}
      <div>
        <Backdrop className={classes.backdrop} open={loader}>
          <CircularProgress />
        </Backdrop>
      </div>
    </Root>
  );
}
