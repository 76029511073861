import React from "react";
import Header from "components/Headers/Header";
import RevenueSection from "./RevenueSection";
import LoyaltyVisits from "./LoyaltyVisits";
import Container from "reactstrap/lib/Container";
import Graphs from "../../examples/graphs/Graphs";

const Performance = () => {
  return (
    <>
      <Header />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Container className="mt--9" fluid>
        <RevenueSection />
        <LoyaltyVisits />
        <Graphs graphType="restaurantChargings" />
      </Container>
    </>
  );
};

export default Performance;
