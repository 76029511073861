import React from "react";
import { Row, Col, Spinner } from "reactstrap";
import "./Modal.css";
// /import {Link} from 'react-router-dom'

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "store/actions/authActions";
import { registeredComplete } from "store/actions/authActions";
class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
    this.props.registeredComplete();
  }

  render() {
    var { loading } = this.props;
    return (
      <>
        <Col lg="6" md="8">
          {/* Page content */}
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form
                role="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.props.login({
                    email: e.target.email.value,
                    password: e.target.password.value,
                  });
                }}
              >
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      required
                      name="email"
                      autoComplete="new-email"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      required
                      name="password"
                      autoComplete="new-password"
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button disabled={loading} className="my-4" color="primary">
                    {loading ? <Spinner size="sm" /> : "Sign in"}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <Link to="/forgetpassword">
                {" "}
                <small className="white">Forgot password?</small>{" "}
              </Link>
            </Col>
            <Col xs="6" className="text-right">
              <Link to="/register">
                {" "}
                <small className="white">Don't have an account?</small>{" "}
              </Link>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export function mapStateToProps(state) {
  return {
    uid: state.auth.uid,
    loading: state.auth.requested,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    login: (credentials) => dispatch(login(credentials)),
    registeredComplete: () => dispatch(registeredComplete()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
