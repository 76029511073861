import { useEffect, useState } from "react";

import FormGroup from "reactstrap/lib/FormGroup";
import FormText from "reactstrap/lib/FormText";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import Button from "reactstrap/lib/Button";

export const AddOnItems = ({
  menu = [],
  addOnItemIds = [],
  onAddOnItemIdsChange = () => {},
}) => {
  // Filter addOnItemIds so they only contain ids that still exist in the menu.
  const availableAddOnItemIds = addOnItemIds.filter((itemId) =>
    menu.some((item) => item.id === itemId)
  );

  // Only menu items that have no choices and no optionalChoices are eligible as
  // add-on items. Also, filter out any items that have already been added.
  const eligibleAddOnItems = menu.filter(
    (item) =>
      (!item.choices || Object.keys(item.choices).length === 0) &&
      (!item.optionalChoices ||
        Object.keys(item.optionalChoices).length === 0) &&
      !availableAddOnItemIds.includes(item.id)
  );

  const firstEligibleAddOnItem = eligibleAddOnItems[0];

  const [selectedAddOnItemId, setSelectedAddOnItemId] = useState(
    firstEligibleAddOnItem?.id ?? ""
  );

  useEffect(() => {
    setSelectedAddOnItemId(firstEligibleAddOnItem?.id ?? "");
  }, [firstEligibleAddOnItem?.id]);

  return (
    <FormGroup>
      <Label>Add-on items</Label>
      <FormText>
        Other menu items the customer can add to their cart when viewing this
        menu item.
      </FormText>
      <div
        className="d-flex flex-column justify-content-between"
        style={{ gap: "0.75rem" }}
      >
        {addOnItemIds?.map((itemId) => {
          return (
            <div
              key={itemId}
              className="d-flex justify-content-between align-items-center"
              style={{ gap: "0.5rem" }}
            >
              <p className="m-0">{itemId}</p>
              <Button
                type="button"
                onClick={() => {
                  const nextAddOnItemIds = addOnItemIds.filter(
                    (item) => itemId !== item
                  );

                  onAddOnItemIdsChange(nextAddOnItemIds);
                }}
              >
                Remove
              </Button>
            </div>
          );
        })}
        <div
          className="d-flex justify-content-between"
          style={{ gap: "0.5rem" }}
        >
          <Input
            type="select"
            name="addOnItems"
            disabled={!eligibleAddOnItems.length === 0}
            value={selectedAddOnItemId}
            onChange={(event) => {
              setSelectedAddOnItemId(event.currentTarget.value);
            }}
          >
            {eligibleAddOnItems.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.id}
                </option>
              );
            })}
          </Input>
          <Button
            color="success"
            type="button"
            disabled={!eligibleAddOnItems.length === 0}
            onClick={() => {
              if (selectedAddOnItemId) {
                onAddOnItemIdsChange([...addOnItemIds, selectedAddOnItemId]);
              }
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </FormGroup>
  );
};
