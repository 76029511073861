import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyAJXBht6uUT0xoNa6xv0wbMWn_-_QgDxlk",
  authDomain: "new-practice-6441a.firebaseapp.com",
  databaseURL: "https://new-practice-6441a.firebaseio.com",
  projectId: "new-practice-6441a",
  storageBucket: "new-practice-6441a.appspot.com",
  messagingSenderId: "545387286490",
  appId: "1:545387286490:web:985b4c2344d3b57df0bae3",
  measurementId: "G-66531Y151N",
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({
  ignoreUndefinedProperties: true,
  merge: true,
});

/**
 * TODO: Update to module style usage
 * @see https://firebase.google.com/docs/web/modular-upgrade
 */
export default firebase;
