import React from "react";
import LoyaltyVisitsGraph from "./LoyaltyVisitsGraph";
import RestaurantChargingsGraph from "./RestaurantChargingsGraph";

const Graphs = ({ graphType }) => {
  if (graphType === "loyaltyVisits") {
    return <LoyaltyVisitsGraph />;
  } else if (graphType === "restaurantChargings") {
    return <RestaurantChargingsGraph />;
  }
};

export default Graphs;
