import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Grid } from "@mui/material";
import moment from "moment";

const PREFIX = "ViewOrderDetailDialogs";

const classes = {
  root: `${PREFIX}-root`,
  root2: `${PREFIX}-root2`,
  closeButton: `${PREFIX}-closeButton`,
  input: `${PREFIX}-input`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root2}`]: {
    margin: 0,
    padding: theme.spacing(2),
  },

  [`& .${classes.closeButton}`]: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  [`& .${classes.input}`]: {
    width: 42,
  },
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = MuiDialogContent;

const ViewOrderDetailDialogs = (props) => {
  //   const [open, setOpen] = useState(true);

  const handleClose = () => {
    props.handleClose();
  };

  const handleDateFormat = (date) => {
    const fireBaseTime = new Date(
      date._seconds * 1000 + date._nanoseconds / 1000000
    );
    const newDate = fireBaseTime.toDateString();
    const atTime = fireBaseTime.toLocaleTimeString();
    return { date: newDate, time: atTime };
  };

  return (
    <Root>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Order Detail
        </DialogTitle>
        <DialogContent
          dividers
          classes={{
            root: classes.root,
          }}
        >
          <div className="m-sm-30">
            <ValidatorForm onError={() => null}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  Name :{" "}
                  {props.data.deliver_to.firstName
                    ? `${props.data.deliver_to.firstName} ${
                        props.data.deliver_to.lastName
                          ? props.data.deliver_to.lastName
                          : ""
                      }`
                    : "N/A"}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  Created At :{" "}
                  {moment(handleDateFormat(props.data.created_at).date).format(
                    "MM-DD-YYYY"
                  )}{" "}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  Sub Total : {props.data.subTotal}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  Total : {props.data.total}
                </Grid>
                {props.data.order_items.length > 0 &&
                  props.data.order_items.map((el, index) => {
                    return (
                      <Grid key={index} item lg={12} md={12} sm={12} xs={12}>
                        Order Item # {index + 1} <br></br>
                        Item : {el.item_id} <br></br>
                        Description : {el.item_description} <br></br>
                        Price : {el.item_price} <br></br>
                        Total : {el.item_total} <br></br>
                        Options Total : {el.optionsTotal} <br></br>
                        Quantity : {el.qty} <br></br>
                      </Grid>
                    );
                  })}
              </Grid>
            </ValidatorForm>
          </div>
        </DialogContent>
      </Dialog>
    </Root>
  );
};

export default ViewOrderDetailDialogs;
