import firebase from "../firebase";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
export const getUser = (uid) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .onSnapshot((user) => {
        dispatch({ type: "GET_USER", user: { id: user.id, ...user.data() } });
      });
  };
};
export const getRestaurant = (uid) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Restaurants Philadelphia")
      .doc(uid)
      .onSnapshot((user) => {
        dispatch({
          type: "GET_RESTAURANT",
          restaurant: { id: user.id, ...user.data() },
        });
      });
  };
};

export const getUsers = () => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .onSnapshot((query) => {
        var tempInvitations = [];
        query.forEach((doc) => {
          tempInvitations.push({ id: doc.id, ...doc.data() });
        });
        dispatch({
          type: "GET_USERS",
          users: tempInvitations,
        });
      });
  };
};

export const updateUser = (
  user,
  image,
  id,
  deliveryChangeSchedule,
  pickupChangeSchedule,
  handleResponse
) => {
  return async (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });
    var url = "";
    var us = await firebase
      .firestore()
      .collection("Restaurants Philadelphia")
      .doc(id)
      .get();
    if (us.exists && us.data().Image) {
      url = us.data().Image;
    }
    if (image) {
      if (url) {
        await firebase.storage().refFromURL(url).delete();
      }
      var data = await firebase
        .storage()
        .ref()
        .child("test_storage/" + uuidv4() + "." + image.name.split(".").pop())
        .put(image);
      url = await data.ref.getDownloadURL();
    }

    if (url) {
      firebase
        .firestore()
        .collection("Restaurants Philadelphia")
        .doc(id)
        .update({ ...user, Image: url })
        .then((query) => {
          axios
            .post(
              `https://us-central1-new-practice-6441a.cloudfunctions.net/setScheduleForDeliveryOrPickup`,
              {
                // axios
                // .post(
                //   `http://localhost:5001/new-practice-6441a/us-central1/setScheduleForDeliveryOrPickup`,
                //   {
                deliveryChangeSchedule: deliveryChangeSchedule,
                pickupChangeSchedule: pickupChangeSchedule,
                restaurantName: id,
              }
            )
            .then((res) => {
              dispatch({
                type: "UPDATE_RESTAURANT",
              });

              handleResponse();
              alert("Restaurant Details Updated");
            })
            .catch((error) => {
              handleResponse();
              alert("Error occurred while updating schedule");
            })
            .finally(() => {
              dispatch({
                type: "ACTION_REQUEST_END",
              });
            });
        });
    } else {
      firebase
        .firestore()
        .collection("Restaurants Philadelphia")
        .doc(id)
        .update({ ...user })
        .then((query) => {
          axios
            .post(
              `https://us-central1-new-practice-6441a.cloudfunctions.net/setScheduleForDeliveryOrPickup`,
              {
                // axios
                // .post(
                //   `http://localhost:5001/new-practice-6441a/us-central1/setScheduleForDeliveryOrPickup`,
                //   {
                deliveryChangeSchedule: deliveryChangeSchedule,
                pickupChangeSchedule: pickupChangeSchedule,
                restaurantName: id,
              }
            )
            .then((res) => {
              handleResponse();
              alert("Restaurant Details Updated");
              dispatch({
                type: "UPDATE_RESTAURANT",
              });
            })
            .catch((error) => {
              handleResponse();
              alert("Error occurred while updating schedule");
            })
            .finally(() => {
              dispatch({
                type: "ACTION_REQUEST_END",
              });
            });
        });
    }
  };
};
