import React from "react";
import { NavLink as NavLinkRRD, withRouter } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import "../../views/examples/Modal.css";
import { connect } from "react-redux";
import {
  Button,
  Collapse,
  Form,
  InputGroupAddon,
  InputGroup,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { logout } from "store/actions/authActions";
import ColorsContext from "./../../utils/context";

class Sidebar extends React.Component {
  static contextType = ColorsContext;
  state = {
    collapseOpen: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} style={{ color: "#E8C85E" }} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    const { routes } = this.props;

    return (
      <Navbar
        // className="navbar-vertical fixed-left navbar-light bg-white"
        className="navbar-vertical fixed-left navbar-light"
        expand="md"
        id="sidenav-main"
        style={{ background: this.context.colors.sidenav }}
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>

          <span>
            {" "}
            <h4>Live Better- Restaurants</h4>
          </span>

          <Nav className="align-items-center d-md-none">
            <Button
              size="md"
              style={{ background: "#E8C85E", color: "black" }}
              onClick={(e) => {
                e.preventDefault();
                this.props.history.replace("/");
                this.props.logout();
              }}
            >
              Logout
            </Button>
          </Nav>

          <Collapse navbar isOpen={this.state.collapseOpen}>
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="9">
                  Live Better- Restaurants
                </Col>
                <Col className="collapse-close text-right" xs="3">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
              </InputGroup>
            </Form>

            <Nav navbar>{this.createLinks(routes)}</Nav>

            <hr className="my-3" />

            <Nav className="mb-md-3" navbar></Nav>
            <Nav className="mb-md-3" navbar></Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export function mapStateToProps(state) {
  return {
    // auth: state.auth,
    uid: state.auth.uid,
    loading: state.auth.requested,
    // registered: state.auth.registered,
    // cars: state.cars.cars,
    // user: state.auth.user,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // getCars: () => dispatch(getCars()),
    // getUser: (uid) => dispatch(getUser(uid)),
    // viewCar: (car) => dispatch(viewCar(car)),
    // addFav: (fav) => dispatch(addFav(fav)),
    // removeFav: (fav) => dispatch(removeFav(fav)),
    logout: () => dispatch(logout()),
    // register: (credentials, password) =>
    // dispatch(register(credentials, password)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
