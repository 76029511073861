import Header from "components/Headers/Header";
import React, { Component, Fragment } from "react";
import Button from "reactstrap/lib/Button";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import "bootstrap/dist/css/bootstrap.css";
import { connect } from "react-redux";
import Container from "reactstrap/lib/Container";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import FormText from "reactstrap/lib/FormText";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import Row from "reactstrap/lib/Row";
import { Col, Spinner } from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { AddOnItems } from "components/AddOnItems";
import { getMenu } from "store/actions/menuActions";
import { getUser } from "store/actions/userActions";
import {
  addMenuItem,
  editMenuItem,
  deleteMenuItem,
} from "store/actions/menuActions";
import { getRestaurant } from "store/actions/userActions";
import { outOfStockChoice } from "store/actions/menuActions";
import { outOfStockOptionalChoice } from "store/actions/menuActions";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
// import { Checkbox, Select, MenuItem } from "@mui/material";
import { Select, MenuItem } from "@mui/material";

class Menu extends Component {
  componentDidMount() {
    var { getUser, uid, getMenu, getRestaurant, user } = this.props;
    getUser(uid);
    getMenu(user.restaurant);
    getRestaurant(user.restaurant);
    let { menu } = this.props;
    if (menu.length > 0) {
      if (
        menu.findIndex((el) => {
          return (
            el.isVegan !== undefined &&
            el.isVegan !== null &&
            el.isVegan === false
          );
        }) > -1
      ) {
        this.setState({
          nonVeganMenuAvailable: true,
        });
      }
    } else {
      this.checkNonVeganMenuAvailable();
    }
  }

  checkNonVeganMenuAvailable = () => {
    setTimeout(() => {
      let { menu } = this.props;
      if (menu.length > 0) {
        if (
          menu.findIndex((el) => {
            return (
              el.isVegan !== undefined &&
              el.isVegan !== null &&
              el.isVegan === false
            );
          }) > -1
        ) {
          this.setState({
            nonVeganMenuAvailable: true,
          });
        }
      }
    }, 1500);
  };

  checkIsNonVeg;
  state = {
    modal: false,
    editModal: "",
    viewModal: false,
    choiceFormatError: "",
    choiceFormatErrorIdxs: [],
    optChoiceFormatError: "",
    optChoiceFormatErrorIdxs: [],
    viewQty: "",
    viewDesc: "",
    viewTitle: "",
    selectedAddOnItemId: "",
    addOnItems: [],
    editAddOnItems: [],
    choices: [],
    editChoices: [],
    optionalChoices: [],
    editOptionalChoices: [],
    dropdownOpen: false,
    // Code portion for multi deletion
    // selectedForDeletion: [],
    value: "asc",
    menuDropDownText: "Vegan Menu",
    nonVeganMenuAvailable: false,
    isVeganItem: true,
    addItemPrice: "",
    editItemPrice: "",
  };

  additionalDescriptionTextForNonVeganItem = `. Non vegan options are added to make ordering easier with friends and family. You are not able to check out with only non vegan items in cart.`;

  additionalNameTextForNonVeganItem = `**NON VEGAN** `;

  dropToggle = (e) => {
    e.preventDefault();
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  viewToggle = (e) => {
    this.setState({
      viewModal: !this.state.viewModal,
    });
  };
  setAsc = (menu) => {
    menu.sort((a, b) => {
      if (a.category.toLowerCase() < b.category.toLowerCase()) {
        return -1;
      }
      if (a.category.toLowerCase() > b.category.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };
  setDes = (menu) => {
    menu.sort((a, b) => {
      if (a.category.toLowerCase() > b.category.toLowerCase()) {
        return -1;
      }
      if (a.category.toLowerCase() < b.category.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };
  valueChange = (e) => {
    this.setState({ value: e.target.value });
  };
  handleAddItemPrice = (e) => {
    let value = e.target.value * 1;
    if (!isNaN(value) || e.target.value === ".") {
      this.setState({ addItemPrice: e.target.value });
    }
  };
  handleEditItemPrice = (e) => {
    let value = e.target.value * 1;
    if (!isNaN(value) || e.target.value === ".") {
      this.setState({ editItemPrice: e.target.value });
    }
  };
  addChoice = (e) => {
    e.preventDefault();
    var choices = this.state.choices;
    choices.push({ key: "", value: 0, choice: "" });
    this.setState({ choices });
  };
  addOptionalChoice = (e) => {
    e.preventDefault();
    var optionalChoices = this.state.optionalChoices;
    optionalChoices.push({ key: "", value: 0, choice: "" });
    this.setState({ optionalChoices });
  };
  addEditChoice = (e) => {
    e.preventDefault();
    var choices = this.state.editChoices;
    choices.push({ key: "", value: 0, choice: "" });
    this.setState({ editChoices: choices });
  };
  addEditOptionalChoice = (e) => {
    e.preventDefault();
    var optionalChoices = this.state.editOptionalChoices;
    optionalChoices.push({ key: "", value: 0 });
    this.setState({ editOptionalChoices: optionalChoices, choice: "" });
  };

  toggle = (e) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal });
    this.setState({
      addOnItems: [],
      editAddOnItems: [],
      choices: [],
      editChoices: [],
      optionalChoices: [],
      editOptionalChoices: [],
    });
  };

  editToggle = (id) => {
    if (this.state.editModal === id) {
      this.setState({
        editModal: "",
        addOnItems: [],
        editAddOnItems: [],
        choices: [],
        editChoices: [],
        optionalChoices: [],
        editOptionalChoices: [],
      });
    } else {
      this.setState({
        editModal: id,
        addOnItems: [],
        editAddOnItems: this.props.menu.find((_, index) => index === id)
          ?.addOnItems,
        choices: [],
        editChoices: [],
        optionalChoices: [],
        editOptionalChoices: [],
      });
    }
    this.setState({});
  };

  // Code portion for multiple deletion of items ...

  // handleSelectForDeletion = (event, id) => {
  //   let selectedMenus = [...this.state.selectedForDeletion];
  //   if (id) {
  //     if (selectedMenus.includes(id)) {
  //       let indexOfId = selectedMenus.indexOf(id);
  //       if (indexOfId > -1) {
  //         selectedMenus.splice(indexOfId, 1);
  //       }
  //     } else {
  //       selectedMenus.push(id);
  //     }
  //   } else {
  //     selectedMenus = [];
  //     if (event.target.checked === true) {
  //       const { menu } = this.props;
  //       for (let i = 0; i < menu.length; i++) {
  //         selectedMenus.push(menu[i].id);
  //       }
  //     }
  //   }
  //   this.setState({ selectedForDeletion: selectedMenus });
  // };

  // handlePressDeleteIcon = () => {
  //   if (this.state.selectedForDeletion.length < 1) {
  //     alert("Please select some item(s) for deletion");
  //     return;
  //   }
  //   let { menu } = this.props;

  //   let msg = "Are you sure you want to delete all the items";

  //   if (this.state.selectedForDeletion.length !== menu.length) {
  //     msg = "Are you sure you want to delete the following item(s)";
  //     let newLine = "\r\n";

  //     msg += newLine;
  //     for (let i = 0; i < this.state.selectedForDeletion.length; i++) {
  //       msg += newLine;
  //       msg += this.state.selectedForDeletion[i];
  //     }
  //   }

  //   if (window.confirm(msg)) {
  //     this.handleDeleteMenuItems();
  //   }
  // };

  // handleDeleteMenuItems = () => {
  //   let { restaurantId } = this.props;
  //   this.props.deleteMenuItem(this.state.selectedForDeletion, restaurantId);
  //   this.setState({ selectedForDeletion: [] });
  // };

  handleDeleteSingleMenuItem = (itemId) => {
    if (window.confirm(`Are you sure you want to delete ${itemId}`)) {
      let { restaurantId } = this.props;
      this.props.deleteMenuItem(itemId, restaurantId);
    }
  };

  handleChangeMenu = (event) => {
    this.setState({
      menuDropDownText: event.target.value,
    });
  };

  toObject = (arr) => {
    var rv = {};
    for (var i = 0; i < arr.length; ++i) {
      rv[arr[i].key] = arr[i].value;
    }
    return rv;
  };

  render() {
    let { menu, loading, restaurantId } = this.props;

    return (
      <div>
        <>
          <Header />
          <Container className="mt--9" fluid>
            <br />
            <br />
            <Row>
              <Col className="text-right">
                <Button
                  className="mb-2"
                  color="success"
                  onClick={this.toggle}
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" /> : "Add Menu Item"}
                </Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                  <ModalHeader toggle={this.toggle}>Add Menu Item</ModalHeader>
                  <ModalBody>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();

                        let finalChoicesBeforeSaving = this.state.choices;
                        for (
                          let i = 0;
                          i < finalChoicesBeforeSaving.length;
                          i++
                        ) {
                          finalChoicesBeforeSaving[i].key =
                            finalChoicesBeforeSaving[i].key.trim() +
                            ")∞(" +
                            finalChoicesBeforeSaving[i].choice;
                        }
                        let finalOptionalChoicesBeforeSaving =
                          this.state.optionalChoices;
                        for (
                          let i = 0;
                          i < finalOptionalChoicesBeforeSaving.length;
                          i++
                        ) {
                          finalOptionalChoicesBeforeSaving[i].key =
                            finalOptionalChoicesBeforeSaving[i].key.trim() +
                            ")∞(" +
                            finalOptionalChoicesBeforeSaving[i].choice;
                        }

                        // Quantity should always be set to the number of categories within choices
                        const quantity = new Set(
                          this.state.choices.map(
                            ({ key }) => key.split(")∞(")[0]
                          )
                        ).size;

                        let itemDescription = e.target.description.value;
                        let itemCategory = e.target.category.value;
                        let itemName = e.target.name.value;
                        if (!this.state.isVeganItem) {
                          itemDescription = `${itemDescription}${this.additionalDescriptionTextForNonVeganItem}`;
                          itemName = `${this.additionalNameTextForNonVeganItem}${itemName}`;
                          itemCategory = `${this.additionalNameTextForNonVeganItem}${itemCategory}`;
                        }

                        this.props.addMenuItem(
                          {
                            addOnItems: this.state.addOnItems,
                            meal_Description: itemDescription,
                            meal_Price: parseFloat(e.target.price.value),
                            category: itemCategory,
                            quantity,
                            choices: finalChoicesBeforeSaving,
                            optionalChoices: finalOptionalChoicesBeforeSaving,
                            isVegan: e.target.isVegan.checked,
                            allowNotes: e.target.allowNotes.checked,
                          },
                          e.target.image?.files[0]
                            ? e.target.image?.files[0]
                            : null,
                          itemName,
                          restaurantId
                        );
                        this.setState({ addItemPrice: "", isVeganItem: true });
                        this.toggle(e);
                      }}
                    >
                      <FormGroup>
                        <Label>Name</Label>
                        <Input name="name" type="text" required />
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="d-flex align-items-center my-2 font-weight-bold m-0"
                          style={{ gap: "0.25rem" }}
                        >
                          <Input
                            className="position-static m-0"
                            name="isVegan"
                            type="checkbox"
                            defaultChecked={true}
                            onChange={() => {
                              this.setState({
                                isVeganItem: !this.state.isVeganItem,
                              });
                            }}
                          />
                          This item is vegan
                        </Label>
                      </FormGroup>
                      <FormGroup>
                        <Label>Category</Label>
                        <Input name="category" type="text" required />
                      </FormGroup>
                      <FormGroup>
                        <Label>Description</Label>
                        <Input name="description" type="textarea" required />
                      </FormGroup>
                      <FormGroup>
                        <Label>Price</Label>
                        <Input
                          name="price"
                          type="text"
                          onChange={this.handleAddItemPrice}
                          value={this.state.addItemPrice}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Modifiers</Label>
                        <FormText>
                          Customers can choose 1 modifier per modifier category.
                        </FormText>
                        {this.state.choices.map((choice, index) => {
                          return (
                            <Fragment key={index}>
                              <div
                                className="d-flex"
                                style={{
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <Input
                                  className="mt-2"
                                  type="text"
                                  style={{
                                    width: "100%",
                                  }}
                                  name={"choicekey" + index}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    let choices = this.state.choices;
                                    choices[index].key = e.target.value;
                                    this.setState({
                                      choices,
                                    });
                                  }}
                                  required
                                  placeholder="Modifier Category"
                                  value={choice.key}
                                />
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <Input
                                  className="mt-2"
                                  type="text"
                                  style={{
                                    width: "100%",
                                  }}
                                  name={"choicekeyChoice" + index}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    let choices = this.state.choices;
                                    choices[index].choice = e.target.value;
                                    this.setState({
                                      choices,
                                    });
                                  }}
                                  required
                                  placeholder="Item Name"
                                  value={choice.choice}
                                />
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <Input
                                  className="mt-2"
                                  type="text"
                                  style={{
                                    width: "100%",
                                  }}
                                  name={"choicevalue" + index}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    let value = e.target.value * 1;
                                    if (
                                      !isNaN(value) ||
                                      e.target.value === "."
                                    ) {
                                      let choices = this.state.choices;
                                      choices[index].value = e.target.value;
                                      this.setState({
                                        choices,
                                      });
                                    }
                                  }}
                                  required
                                  placeholder="0.00"
                                  // defaultValue={choice.value}
                                  value={choice.value}
                                />
                              </div>
                              <div className="text-right mt-2">
                                <Button
                                  type="button"
                                  onClick={(e) => {
                                    let choices = this.state.choices;
                                    choices.splice(index, 1);
                                    this.setState({
                                      choices,
                                    });
                                  }}
                                  color="danger"
                                >
                                  Delete Modifier
                                </Button>
                              </div>
                            </Fragment>
                          );
                        })}
                        <Button
                          color="success"
                          className="mt-2"
                          onClick={(e) => {
                            this.addChoice(e);
                          }}
                        >
                          Add Modifier{" "}
                        </Button>
                      </FormGroup>
                      <FormGroup>
                        <Label className="d-block">Optional Modifiers</Label>
                        <FormText>
                          Customers can choose as many optional modifiers as
                          they like.
                        </FormText>
                        {this.state.optionalChoices.map(
                          (optionalChoice, index) => {
                            return (
                              <>
                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <Input
                                    className="mt-2"
                                    type="text"
                                    name={"optionalchoicekey" + index}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      let optionalChoices =
                                        this.state.optionalChoices;
                                      optionalChoices[index].key =
                                        e.target.value;
                                      this.setState({
                                        optionalChoices,
                                      });
                                    }}
                                    placeholder="Modifier Category"
                                    required
                                    value={optionalChoice.key}
                                  />
                                </div>
                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <Input
                                    className="mt-2"
                                    type="text"
                                    name={"optionalchoicekeyChoice" + index}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      let optionalChoices =
                                        this.state.optionalChoices;
                                      optionalChoices[index].choice =
                                        e.target.value;
                                      this.setState({
                                        optionalChoices,
                                      });
                                    }}
                                    placeholder="Item Name"
                                    required
                                    value={optionalChoice.choice}
                                  />
                                </div>
                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <Input
                                    className="mt-2"
                                    type="text"
                                    style={{
                                      width: "100%",
                                    }}
                                    name={"optionalchoicevalue" + index}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      let value = e.target.value * 1;
                                      if (
                                        !isNaN(value) ||
                                        e.target.value === "."
                                      ) {
                                        let optionalChoices =
                                          this.state.optionalChoices;
                                        optionalChoices[index].value =
                                          e.target.value;
                                        this.setState({
                                          optionalChoices,
                                        });
                                      }
                                    }}
                                    placeholder="0.00"
                                    required
                                    value={optionalChoice.value}
                                  />
                                </div>

                                <div className="text-right mt-2">
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      let optionalChoices =
                                        this.state.optionalChoices;
                                      optionalChoices.splice(index, 1);
                                      this.setState({
                                        optionalChoices,
                                      });
                                    }}
                                    color="danger"
                                  >
                                    Delete Modifier
                                  </Button>
                                </div>
                              </>
                            );
                          }
                        )}
                        <Button
                          className="mt-2"
                          color="success"
                          onClick={(e) => {
                            this.addOptionalChoice(e);
                          }}
                        >
                          Add Optional Modifier{" "}
                        </Button>
                      </FormGroup>
                      <AddOnItems
                        menu={menu}
                        addOnItemIds={this.state.addOnItems}
                        onAddOnItemIdsChange={(addOnItems) =>
                          this.setState({ addOnItems })
                        }
                      />
                      {this.state.isVeganItem === true && (
                        <FormGroup>
                          <Label>Image</Label>
                          <Input name="image" type="file" />
                        </FormGroup>
                      )}

                      <FormGroup>
                        <Label
                          className="d-flex align-items-start my-2 m-0"
                          style={{ gap: "0.5rem" }}
                        >
                          <Input
                            className="position-static m-0 mt-1"
                            name="allowNotes"
                            type="checkbox"
                            defaultChecked={true}
                          />
                          Allow customers to add notes or make special requests
                          for this item
                        </Label>
                      </FormGroup>
                      <Row>
                        <Col className="mt-2 text-right">
                          <Button color="success">Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
                <Modal isOpen={this.state.viewModal} toggle={this.viewToggle}>
                  <ModalHeader toggle={this.viewToggle}>
                    {this.state.viewTitle}
                  </ModalHeader>
                  <ModalBody>
                    <Row className="justify-content-between mx-2">
                      <Label>
                        <b>Description</b>
                      </Label>
                      <span className="ml-2"> {this.state.viewDesc}</span>
                    </Row>
                    <br />
                    <Row className="justify-content-between mx-2">
                      <Label>
                        <b>How many modifiers must the customer choose?</b>
                      </Label>
                      <span className="ml-2">{this.state.viewQty}</span>
                    </Row>
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Select
                      variant="standard"
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={this.state.menuDropDownText}
                      onChange={this.handleChangeMenu}
                    >
                      <MenuItem value="Vegan Menu">Vegan Menu</MenuItem>
                      {this.state.nonVeganMenuAvailable === true && (
                        <MenuItem value="Non Vegan Menu">
                          Non Vegan Menu
                        </MenuItem>
                      )}
                    </Select>
                  </CardHeader>
                  <CardBody>
                    <Label>Sort By</Label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Dropdown
                        className="mb-2"
                        isOpen={this.state.dropdownOpen}
                        toggle={this.dropToggle}
                      >
                        <DropdownToggle color="primary" caret>
                          Category
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => this.setAsc(menu)}>
                            Ascending
                          </DropdownItem>
                          <DropdownItem onClick={() => this.setDes(menu)}>
                            Descending
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      {/*
                       Code portion for Multi selection for deletion
                      <div style={{ marginRight: "45px" }}>
                        <i
                          onClick={this.handlePressDeleteIcon}
                          className="fa fa-trash"
                          aria-hidden="true"
                        ></i>
                      </div> */}
                    </div>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Image</Th>
                          <Th>Category</Th>
                          <Th>Price</Th>
                          <Th>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {menu &&
                          menu.map((item, index) => {
                            const isItemVisible =
                              this.state.menuDropDownText === "Vegan Menu"
                                ? item.isVegan !== false
                                : item.isVegan === false;

                            return (
                              <Fragment key={index}>
                                {isItemVisible ? (
                                  <Tr
                                    key={index}
                                    style={{
                                      borderBottom: "1px solid grey",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Td
                                      onClick={() => {
                                        this.setState({
                                          viewTitle: item.id,
                                          viewQty: item.quantity,
                                          viewDesc: item.meal_Description,
                                        });
                                        this.viewToggle();
                                      }}
                                    >
                                      {item.id}
                                    </Td>
                                    <Td
                                      onClick={() => {
                                        this.setState({
                                          viewTitle: item.id,
                                          viewQty: item.quantity,
                                          viewDesc: item.meal_Description,
                                        });
                                        this.viewToggle();
                                      }}
                                    >
                                      {item.picture !== "" ? (
                                        <img
                                          alt="Not available"
                                          src={item.picture}
                                          style={{
                                            width: "80px",
                                            height: "80px",
                                          }}
                                        />
                                      ) : (
                                        "No Image"
                                      )}
                                    </Td>
                                    <Td
                                      onClick={() => {
                                        this.setState({
                                          viewTitle: item.id,
                                          viewQty: item.quantity,
                                          viewDesc: item.meal_Description,
                                        });
                                        this.viewToggle();
                                      }}
                                    >
                                      {item.category}
                                    </Td>
                                    <Td
                                      onClick={() => {
                                        this.setState({
                                          viewTitle: item.id,
                                          viewQty: item.quantity,
                                          viewDesc: item.meal_Description,
                                        });
                                        this.viewToggle();
                                      }}
                                    >
                                      $ {item.meal_Price}
                                    </Td>
                                    <Td>
                                      {/*
                                      Code portion for Multi selection for deletion
                                      <Checkbox
                                        checked={this.state.selectedForDeletion.includes(
                                          item.id
                                        )}
                                        onChange={(event) => {
                                          this.handleSelectForDeletion(
                                            event,
                                            item.id
                                          );
                                        }}
                                      ></Checkbox> */}

                                      <Button
                                        size="sm"
                                        color="success"
                                        className="m-2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({
                                            editItemPrice: item.meal_Price,
                                          });
                                          this.editToggle(index);
                                          let keys = Object.keys(item.choices);
                                          var newChoices = [];
                                          for (
                                            let i = 0;
                                            i < keys.length;
                                            i++
                                          ) {
                                            newChoices.push({
                                              key: keys[i],
                                              value: parseFloat(
                                                item.choices[keys[i]]
                                              ),
                                            });
                                          }
                                          let optionalKeys = Object.keys(
                                            item.optionalChoices
                                          );
                                          var newOptionalChoices = [];
                                          for (
                                            let i = 0;
                                            i < optionalKeys.length;
                                            i++
                                          ) {
                                            newOptionalChoices.push({
                                              key: optionalKeys[i],
                                              value: parseFloat(
                                                item.optionalChoices[
                                                  optionalKeys[i]
                                                ]
                                              ),
                                            });
                                          }
                                          this.setState({
                                            editOptionalChoices:
                                              newOptionalChoices || [],
                                            editChoices: newChoices || [],
                                          });
                                        }}
                                        disabled={loading}
                                      >
                                        {loading ? (
                                          <Spinner size="sm" />
                                        ) : (
                                          "Edit"
                                        )}
                                      </Button>
                                      <Button
                                        size="sm"
                                        color="danger"
                                        className="m-2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleDeleteSingleMenuItem(
                                            item.id
                                          );
                                        }}
                                        disabled={loading}
                                      >
                                        {loading ? (
                                          <Spinner size="sm" />
                                        ) : (
                                          "Delete"
                                        )}
                                      </Button>
                                      <Modal
                                        isOpen={this.state.editModal === index}
                                        toggle={(e) => {
                                          e.preventDefault();
                                          this.editToggle(index);
                                        }}
                                      >
                                        <ModalHeader
                                          toggle={(e) => {
                                            e.preventDefault();
                                            this.editToggle(index);
                                          }}
                                        >
                                          Edit {item.id}
                                        </ModalHeader>
                                        <ModalBody>
                                          <Form
                                            onSubmit={(e) => {
                                              e.preventDefault();

                                              let finalChoicesBeforeSaving =
                                                this.state.editChoices;

                                              for (
                                                let i = 0;
                                                i <
                                                finalChoicesBeforeSaving.length;
                                                i++
                                              ) {
                                                if (
                                                  finalChoicesBeforeSaving[i]
                                                    .category &&
                                                  finalChoicesBeforeSaving[i]
                                                    .modifier
                                                ) {
                                                  finalChoicesBeforeSaving[
                                                    i
                                                  ].key =
                                                    finalChoicesBeforeSaving[
                                                      i
                                                    ].category.trim() +
                                                    ")∞(" +
                                                    finalChoicesBeforeSaving[i]
                                                      .modifier;
                                                } else if (
                                                  !finalChoicesBeforeSaving[i]
                                                    .category &&
                                                  finalChoicesBeforeSaving[i]
                                                    .modifier
                                                ) {
                                                  finalChoicesBeforeSaving[
                                                    i
                                                  ].key =
                                                    finalChoicesBeforeSaving[
                                                      i
                                                    ].key.split(")∞(")[0] +
                                                    ")∞(" +
                                                    finalChoicesBeforeSaving[i]
                                                      .modifier;
                                                } else if (
                                                  finalChoicesBeforeSaving[i]
                                                    .category &&
                                                  !finalChoicesBeforeSaving[i]
                                                    .modifier
                                                ) {
                                                  finalChoicesBeforeSaving[
                                                    i
                                                  ].key =
                                                    finalChoicesBeforeSaving[
                                                      i
                                                    ].category.trim() +
                                                    ")∞(" +
                                                    finalChoicesBeforeSaving[
                                                      i
                                                    ].key.split(")∞(")[1];
                                                }
                                              }

                                              let finalOptionalChoicesBeforeSaving =
                                                this.state.editOptionalChoices;
                                              for (
                                                let i = 0;
                                                i <
                                                finalOptionalChoicesBeforeSaving.length;
                                                i++
                                              ) {
                                                if (
                                                  finalOptionalChoicesBeforeSaving[
                                                    i
                                                  ].category &&
                                                  finalOptionalChoicesBeforeSaving[
                                                    i
                                                  ].modifier
                                                ) {
                                                  finalOptionalChoicesBeforeSaving[
                                                    i
                                                  ].key =
                                                    finalOptionalChoicesBeforeSaving[
                                                      i
                                                    ].category.trim() +
                                                    ")∞(" +
                                                    finalOptionalChoicesBeforeSaving[
                                                      i
                                                    ].modifier;
                                                } else if (
                                                  !finalOptionalChoicesBeforeSaving[
                                                    i
                                                  ].category &&
                                                  finalOptionalChoicesBeforeSaving[
                                                    i
                                                  ].modifier
                                                ) {
                                                  finalOptionalChoicesBeforeSaving[
                                                    i
                                                  ].key =
                                                    finalOptionalChoicesBeforeSaving[
                                                      i
                                                    ].key.split(")∞(")[0] +
                                                    ")∞(" +
                                                    finalOptionalChoicesBeforeSaving[
                                                      i
                                                    ].modifier;
                                                } else if (
                                                  finalOptionalChoicesBeforeSaving[
                                                    i
                                                  ].category &&
                                                  !finalOptionalChoicesBeforeSaving[
                                                    i
                                                  ].modifier
                                                ) {
                                                  finalOptionalChoicesBeforeSaving[
                                                    i
                                                  ].key =
                                                    finalOptionalChoicesBeforeSaving[
                                                      i
                                                    ].category.trim() +
                                                    ")∞(" +
                                                    finalOptionalChoicesBeforeSaving[
                                                      i
                                                    ].key.split(")∞(")[1];
                                                }
                                              }

                                              // Quantity should always be set to the number of categories within editChoices
                                              const quantity = new Set(
                                                this.state.editChoices.map(
                                                  ({ key }) =>
                                                    key.split(")∞(")[0]
                                                )
                                              ).size;

                                              let itemDescription =
                                                e.target.description.value;
                                              let itemCategory =
                                                e.target.category.value;
                                              if (item.isVegan === false) {
                                                itemDescription = `${itemDescription}${this.additionalDescriptionTextForNonVeganItem}`;
                                                itemCategory = `${this.additionalNameTextForNonVeganItem}${itemCategory}`;
                                              }

                                              let editableObj = {
                                                addOnItems:
                                                  this.state.editAddOnItems,
                                                allowNotes:
                                                  e.target.allowNotes.checked,
                                                meal_Description:
                                                  itemDescription,
                                                meal_Price: parseFloat(
                                                  e.target.price.value
                                                ),
                                                category: itemCategory,
                                                quantity,
                                                outOfStock:
                                                  e.target.out_of_stock
                                                    .value === "true"
                                                    ? true
                                                    : false,
                                                choices:
                                                  finalChoicesBeforeSaving,
                                                optionalChoices:
                                                  finalOptionalChoicesBeforeSaving,
                                              };
                                              this.props.editMenuItem(
                                                editableObj,
                                                item.isVegan !== false
                                                  ? e.target.image.files[0]
                                                  : null,
                                                item.id,
                                                restaurantId
                                              );
                                              this.setState({
                                                editItemPrice: "",
                                              });
                                              this.editToggle(index);
                                            }}
                                          >
                                            <FormGroup>
                                              <Label
                                                className="d-flex align-items-center my-2 font-weight-bold m-0"
                                                style={{ gap: "0.25rem" }}
                                              >
                                                <Input
                                                  disabled
                                                  className="position-static m-0"
                                                  name="isVegan"
                                                  type="checkbox"
                                                  defaultChecked={
                                                    item.isVegan !== false
                                                  }
                                                />
                                                This item is vegan
                                              </Label>
                                            </FormGroup>
                                            <FormGroup>
                                              <Label>Category</Label>
                                              <Input
                                                name="category"
                                                type="text"
                                                required
                                                defaultValue={
                                                  item.isVegan !== false
                                                    ? item?.category
                                                    : item?.category?.split(
                                                        this
                                                          .additionalNameTextForNonVeganItem
                                                      )[1]
                                                }
                                              />
                                            </FormGroup>
                                            <FormGroup>
                                              <Label>Description</Label>
                                              <Input
                                                name="description"
                                                type="textarea"
                                                required
                                                defaultValue={
                                                  item?.meal_Description?.split(
                                                    this
                                                      .additionalDescriptionTextForNonVeganItem
                                                  )[0]
                                                }
                                              />
                                            </FormGroup>
                                            <FormGroup>
                                              <Label>Price</Label>
                                              <Input
                                                name="price"
                                                type="text"
                                                onChange={
                                                  this.handleEditItemPrice
                                                }
                                                value={this.state.editItemPrice}
                                                required
                                                // defaultValue={item.meal_Price}
                                              />
                                            </FormGroup>
                                            <FormGroup>
                                              <Label>Modifiers</Label>
                                              <FormText>
                                                Customers can choose 1 modifier
                                                per modifier category.
                                              </FormText>
                                              {this.state.editChoices.map(
                                                (choice, index) => {
                                                  return (
                                                    <React.Fragment key={index}>
                                                      <div
                                                        className="d-flex"
                                                        style={{
                                                          justifyContent:
                                                            "space-evenly",
                                                        }}
                                                      >
                                                        <Input
                                                          className="mt-2"
                                                          type="text"
                                                          name={
                                                            "edit_category" +
                                                            index
                                                          }
                                                          onChange={(e) => {
                                                            e.preventDefault();
                                                            let choices =
                                                              this.state
                                                                .editChoices;
                                                            choices[
                                                              index
                                                            ].category =
                                                              e.target.value;
                                                            this.setState({
                                                              editChoices:
                                                                choices,
                                                            });
                                                          }}
                                                          placeholder={
                                                            choice.key.split(
                                                              ")∞("
                                                            )[0]
                                                              ? choice.key.split(
                                                                  ")∞("
                                                                )[0]
                                                              : "Modifier Category"
                                                          }
                                                        />
                                                      </div>
                                                      <div
                                                        className="d-flex"
                                                        style={{
                                                          justifyContent:
                                                            "space-evenly",
                                                        }}
                                                      >
                                                        <Input
                                                          className="mt-2"
                                                          type="text"
                                                          name={
                                                            "edit_modifier" +
                                                            index
                                                          }
                                                          onChange={(e) => {
                                                            e.preventDefault();
                                                            let choices =
                                                              this.state
                                                                .editChoices;
                                                            choices[
                                                              index
                                                            ].modifier =
                                                              e.target.value;
                                                            this.setState({
                                                              editChoices:
                                                                choices,
                                                            });
                                                          }}
                                                          placeholder={
                                                            choice.key.split(
                                                              ")∞("
                                                            )[1]
                                                              ? choice.key.split(
                                                                  ")∞("
                                                                )[1]
                                                              : "Item Name"
                                                          }
                                                        />
                                                      </div>
                                                      <div
                                                        className="d-flex"
                                                        style={{
                                                          justifyContent:
                                                            "space-evenly",
                                                        }}
                                                      >
                                                        {this.state.editChoices[
                                                          index
                                                        ].value !== -786 ? (
                                                          <Input
                                                            className="mt-2"
                                                            type="text"
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            name={
                                                              "edit_choicevalue" +
                                                              index
                                                            }
                                                            onChange={(e) => {
                                                              e.preventDefault();
                                                              let value =
                                                                e.target.value *
                                                                1;
                                                              if (
                                                                !isNaN(value) ||
                                                                e.target
                                                                  .value === "."
                                                              ) {
                                                                let choices =
                                                                  this.state
                                                                    .editChoices;
                                                                choices[
                                                                  index
                                                                ].value =
                                                                  e.target.value;
                                                                this.setState({
                                                                  editChoices:
                                                                    choices,
                                                                });
                                                              }
                                                            }}
                                                            required
                                                            placeholder="0.00"
                                                            value={choice.value}
                                                          />
                                                        ) : (
                                                          <Input
                                                            className="mt-2"
                                                            type="text"
                                                            readOnly
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            name={
                                                              "edit_choicevalue" +
                                                              index
                                                            }
                                                            onChange={(e) => {
                                                              e.preventDefault();

                                                              let value =
                                                                e.target.value *
                                                                1;
                                                              if (
                                                                !isNaN(value) ||
                                                                e.target
                                                                  .value === "."
                                                              ) {
                                                                let choices =
                                                                  this.state
                                                                    .editChoices;
                                                                choices[
                                                                  index
                                                                ].value =
                                                                  e.target.value;
                                                                this.setState({
                                                                  editChoices:
                                                                    choices,
                                                                });
                                                              }
                                                            }}
                                                            placeholder="0.00"
                                                            required
                                                            value={choice.value}
                                                          />
                                                        )}
                                                      </div>
                                                      <div className="text-right mt-2">
                                                        {this.state.editChoices[
                                                          index
                                                        ].value !== -786 ? (
                                                          <Button
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              let choices =
                                                                this.state
                                                                  .editChoices;
                                                              choices[
                                                                index
                                                              ].value = -786;
                                                              let newOptCh =
                                                                this.toObject([
                                                                  choices[
                                                                    index
                                                                  ],
                                                                ]);
                                                              let rsNewOptCh =
                                                                Object.keys(
                                                                  newOptCh
                                                                );
                                                              let temparr = [];
                                                              menu.forEach(
                                                                (item) => {
                                                                  let newRs =
                                                                    Object.keys(
                                                                      item.choices
                                                                    );
                                                                  if (
                                                                    newRs.some(
                                                                      (r) =>
                                                                        rsNewOptCh.includes(
                                                                          r
                                                                        )
                                                                    )
                                                                  ) {
                                                                    temparr.push(
                                                                      item
                                                                    );
                                                                  }
                                                                }
                                                              );
                                                              temparr.forEach(
                                                                (item) => {
                                                                  let n =
                                                                    Object.keys(
                                                                      item.choices
                                                                    );
                                                                  n.forEach(
                                                                    (key) => {
                                                                      if (
                                                                        key ===
                                                                        rsNewOptCh[0]
                                                                      ) {
                                                                        item.choices[
                                                                          key
                                                                        ] = -786;
                                                                      }
                                                                    }
                                                                  );
                                                                }
                                                              );
                                                              temparr.forEach(
                                                                (item) => {
                                                                  this.props.outOfStockChoice(
                                                                    restaurantId,
                                                                    item.id,
                                                                    item.choices
                                                                  );
                                                                }
                                                              );
                                                              this.setState({
                                                                editChoices:
                                                                  choices,
                                                              });
                                                            }}
                                                            color="info"
                                                          >
                                                            Out of Stock
                                                          </Button>
                                                        ) : (
                                                          <Button
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              let choices =
                                                                this.state
                                                                  .editChoices;
                                                              choices[
                                                                index
                                                              ].value = "";
                                                              let newOptCh =
                                                                this.toObject([
                                                                  choices[
                                                                    index
                                                                  ],
                                                                ]);
                                                              let rsNewOptCh =
                                                                Object.keys(
                                                                  newOptCh
                                                                );
                                                              let temparr = [];
                                                              menu.forEach(
                                                                (item) => {
                                                                  let newRs =
                                                                    Object.keys(
                                                                      item.choices
                                                                    );
                                                                  if (
                                                                    newRs.some(
                                                                      (r) =>
                                                                        rsNewOptCh.includes(
                                                                          r
                                                                        )
                                                                    )
                                                                  ) {
                                                                    temparr.push(
                                                                      item
                                                                    );
                                                                  }
                                                                }
                                                              );
                                                              temparr.forEach(
                                                                (item) => {
                                                                  let n =
                                                                    Object.keys(
                                                                      item.choices
                                                                    );
                                                                  n.forEach(
                                                                    (key) => {
                                                                      if (
                                                                        key ===
                                                                        rsNewOptCh[0]
                                                                      ) {
                                                                        item.choices[
                                                                          key
                                                                        ] = "";
                                                                      }
                                                                    }
                                                                  );
                                                                }
                                                              );
                                                              temparr.forEach(
                                                                (item) => {
                                                                  this.props.outOfStockChoice(
                                                                    restaurantId,
                                                                    item.id,
                                                                    item.choices
                                                                  );
                                                                }
                                                              );
                                                              this.setState({
                                                                editChoices:
                                                                  choices,
                                                              });
                                                            }}
                                                            color="success"
                                                          >
                                                            In Stock
                                                          </Button>
                                                        )}
                                                        <Button
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            let choices =
                                                              this.state
                                                                .editChoices;
                                                            choices.splice(
                                                              index,
                                                              1
                                                            );
                                                            this.setState({
                                                              editChoices:
                                                                choices,
                                                            });
                                                          }}
                                                          color="danger"
                                                        >
                                                          Delete Modifier
                                                        </Button>
                                                      </div>
                                                    </React.Fragment>
                                                  );
                                                }
                                              )}
                                              <Button
                                                color="success"
                                                className="mt-2"
                                                onClick={(e) => {
                                                  this.addEditChoice(e);
                                                }}
                                              >
                                                Add Modifier{" "}
                                              </Button>
                                            </FormGroup>
                                            <FormGroup>
                                              <Label className="d-block">
                                                Optional Modifiers
                                              </Label>
                                              <FormText>
                                                Customers can choose as many
                                                optional modifiers as they like.
                                              </FormText>
                                              {this.state.editOptionalChoices.map(
                                                (optionalChoice, index) => {
                                                  return (
                                                    <React.Fragment key={index}>
                                                      <div
                                                        className="d-flex"
                                                        style={{
                                                          justifyContent:
                                                            "space-evenly",
                                                        }}
                                                      >
                                                        <Input
                                                          className="mt-2"
                                                          type="text"
                                                          name={
                                                            "category" + index
                                                          }
                                                          onChange={(e) => {
                                                            e.preventDefault();
                                                            let optionalChoices =
                                                              this.state
                                                                .editOptionalChoices;
                                                            optionalChoices[
                                                              index
                                                            ].category =
                                                              e.target.value;
                                                            this.setState({
                                                              editOptionalChoices:
                                                                optionalChoices,
                                                            });
                                                          }}
                                                          placeholder={
                                                            optionalChoice.key.split(
                                                              ")∞("
                                                            )[0]
                                                              ? optionalChoice.key.split(
                                                                  ")∞("
                                                                )[0]
                                                              : "Modifier Category"
                                                          }
                                                        />
                                                      </div>
                                                      <div
                                                        className="d-flex"
                                                        style={{
                                                          justifyContent:
                                                            "space-evenly",
                                                        }}
                                                      >
                                                        <Input
                                                          className="mt-2"
                                                          type="text"
                                                          name={
                                                            "modifier" + index
                                                          }
                                                          onChange={(e) => {
                                                            e.preventDefault();
                                                            let optionalChoices =
                                                              this.state
                                                                .editOptionalChoices;
                                                            optionalChoices[
                                                              index
                                                            ].modifier =
                                                              e.target.value;
                                                            this.setState({
                                                              editOptionalChoices:
                                                                optionalChoices,
                                                            });
                                                          }}
                                                          placeholder={
                                                            optionalChoice.key.split(
                                                              ")∞("
                                                            )[1]
                                                              ? optionalChoice.key.split(
                                                                  ")∞("
                                                                )[1]
                                                              : "Item Name"
                                                          }
                                                        />
                                                      </div>
                                                      <div
                                                        className="d-flex"
                                                        style={{
                                                          justifyContent:
                                                            "space-evenly",
                                                        }}
                                                      >
                                                        {this.state
                                                          .editOptionalChoices[
                                                          index
                                                        ].value !== -786 ? (
                                                          <Input
                                                            className="mt-2"
                                                            type="text"
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            name={
                                                              "edit_optionalchoicevalue" +
                                                              index
                                                            }
                                                            onChange={(e) => {
                                                              e.preventDefault();

                                                              let value =
                                                                e.target.value *
                                                                1;
                                                              if (
                                                                !isNaN(value) ||
                                                                e.target
                                                                  .value === "."
                                                              ) {
                                                                let optionalChoices =
                                                                  this.state
                                                                    .editOptionalChoices;
                                                                optionalChoices[
                                                                  index
                                                                ].value =
                                                                  e.target.value;
                                                                this.setState({
                                                                  editOptionalChoices:
                                                                    optionalChoices,
                                                                });
                                                              }
                                                            }}
                                                            required
                                                            placeholder="0.00"
                                                            value={
                                                              optionalChoice.value
                                                            }
                                                          />
                                                        ) : (
                                                          <Input
                                                            className="mt-2"
                                                            type="text"
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            readOnly
                                                            name={
                                                              "edit_optionalchoicevalue" +
                                                              index
                                                            }
                                                            onChange={(e) => {
                                                              e.preventDefault();

                                                              let value =
                                                                e.target.value *
                                                                1;
                                                              if (
                                                                !isNaN(value) ||
                                                                e.target
                                                                  .value === "."
                                                              ) {
                                                                let optionalChoices =
                                                                  this.state
                                                                    .editOptionalChoices;
                                                                optionalChoices[
                                                                  index
                                                                ].value =
                                                                  e.target.value;
                                                                this.setState({
                                                                  editOptionalChoices:
                                                                    optionalChoices,
                                                                });
                                                              }
                                                            }}
                                                            required
                                                            placeholder="0.00"
                                                            value={
                                                              optionalChoice.value
                                                            }
                                                          />
                                                        )}
                                                      </div>
                                                      <div className="text-right mt-2">
                                                        {this.state
                                                          .editOptionalChoices[
                                                          index
                                                        ].value !== -786 ? (
                                                          <Button
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              let optionalChoices =
                                                                this.state
                                                                  .editOptionalChoices;
                                                              optionalChoices[
                                                                index
                                                              ].value = -786;
                                                              let newOptCh =
                                                                this.toObject([
                                                                  optionalChoices[
                                                                    index
                                                                  ],
                                                                ]);
                                                              let rsNewOptCh =
                                                                Object.keys(
                                                                  newOptCh
                                                                );
                                                              let temparr = [];
                                                              menu.forEach(
                                                                (item) => {
                                                                  let newRs =
                                                                    Object.keys(
                                                                      item.optionalChoices
                                                                    );
                                                                  if (
                                                                    newRs.some(
                                                                      (r) =>
                                                                        rsNewOptCh.includes(
                                                                          r
                                                                        )
                                                                    )
                                                                  ) {
                                                                    temparr.push(
                                                                      item
                                                                    );
                                                                  }
                                                                }
                                                              );
                                                              temparr.forEach(
                                                                (item) => {
                                                                  let n =
                                                                    Object.keys(
                                                                      item.optionalChoices
                                                                    );
                                                                  n.forEach(
                                                                    (key) => {
                                                                      if (
                                                                        key ===
                                                                        rsNewOptCh[0]
                                                                      ) {
                                                                        item.optionalChoices[
                                                                          key
                                                                        ] = -786;
                                                                      }
                                                                    }
                                                                  );
                                                                }
                                                              );
                                                              temparr.forEach(
                                                                (item) => {
                                                                  this.props.outOfStockOptionalChoice(
                                                                    restaurantId,
                                                                    item.id,
                                                                    item.optionalChoices
                                                                  );
                                                                }
                                                              );
                                                              this.setState({
                                                                editOptionalChoices:
                                                                  optionalChoices,
                                                              });
                                                            }}
                                                            color="info"
                                                          >
                                                            Out of Stock
                                                          </Button>
                                                        ) : (
                                                          <Button
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              let optionalChoices =
                                                                this.state
                                                                  .editOptionalChoices;
                                                              optionalChoices[
                                                                index
                                                              ].value = "";
                                                              let newOptCh =
                                                                this.toObject([
                                                                  optionalChoices[
                                                                    index
                                                                  ],
                                                                ]);
                                                              let rsNewOptCh =
                                                                Object.keys(
                                                                  newOptCh
                                                                );
                                                              let temparr = [];
                                                              menu.forEach(
                                                                (item) => {
                                                                  let newRs =
                                                                    Object.keys(
                                                                      item.optionalChoices
                                                                    );
                                                                  if (
                                                                    newRs.some(
                                                                      (r) =>
                                                                        rsNewOptCh.includes(
                                                                          r
                                                                        )
                                                                    )
                                                                  ) {
                                                                    temparr.push(
                                                                      item
                                                                    );
                                                                  }
                                                                }
                                                              );
                                                              temparr.forEach(
                                                                (item) => {
                                                                  let n =
                                                                    Object.keys(
                                                                      item.optionalChoices
                                                                    );
                                                                  n.forEach(
                                                                    (key) => {
                                                                      if (
                                                                        key ===
                                                                        rsNewOptCh[0]
                                                                      ) {
                                                                        item.optionalChoices[
                                                                          key
                                                                        ] = "";
                                                                      }
                                                                    }
                                                                  );
                                                                }
                                                              );
                                                              temparr.forEach(
                                                                (item) => {
                                                                  this.props.outOfStockOptionalChoice(
                                                                    restaurantId,
                                                                    item.id,
                                                                    item.optionalChoices
                                                                  );
                                                                }
                                                              );
                                                            }}
                                                            color="success"
                                                          >
                                                            In Stock
                                                          </Button>
                                                        )}
                                                        <Button
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            let optionalChoices =
                                                              this.state
                                                                .editOptionalChoices;
                                                            optionalChoices.splice(
                                                              index,
                                                              1
                                                            );
                                                            this.setState({
                                                              editOptionalChoices:
                                                                optionalChoices,
                                                            });
                                                          }}
                                                          color="danger"
                                                        >
                                                          Delete Modifier
                                                        </Button>
                                                      </div>
                                                    </React.Fragment>
                                                  );
                                                }
                                              )}
                                              <Button
                                                color="success"
                                                className="mt-2"
                                                onClick={(e) => {
                                                  this.addEditOptionalChoice(e);
                                                }}
                                              >
                                                Add Optional Modifier{" "}
                                              </Button>
                                            </FormGroup>
                                            <AddOnItems
                                              menu={menu}
                                              addOnItemIds={
                                                this.state.editAddOnItems
                                              }
                                              onAddOnItemIdsChange={(
                                                editAddOnItems
                                              ) =>
                                                this.setState({
                                                  editAddOnItems,
                                                })
                                              }
                                            />
                                            <FormGroup>
                                              <Label>Out Of Stock</Label>
                                              <Input
                                                type="select"
                                                name="out_of_stock"
                                                defaultValue={
                                                  item.outOfStock
                                                    ? item.outOfStock
                                                    : false
                                                }
                                              >
                                                <option value={true}>
                                                  Yes
                                                </option>
                                                <option value={false}>
                                                  No
                                                </option>
                                              </Input>
                                            </FormGroup>
                                            {item.isVegan !== false && (
                                              <FormGroup>
                                                <Label>Image</Label>
                                                <Input
                                                  name="image"
                                                  type="file"
                                                />
                                              </FormGroup>
                                            )}

                                            <FormGroup>
                                              <Label
                                                className="d-flex align-items-start my-2 m-0"
                                                style={{ gap: "0.5rem" }}
                                              >
                                                <Input
                                                  className="position-static m-0 mt-1"
                                                  name="allowNotes"
                                                  type="checkbox"
                                                  defaultChecked={
                                                    item.allowNotes !== false
                                                  }
                                                />
                                                Allow customers to add notes or
                                                make special requests for this
                                                item
                                              </Label>
                                            </FormGroup>
                                            <Row>
                                              <Col className="mt-2 text-right">
                                                <Button color="success">
                                                  Update
                                                </Button>
                                              </Col>
                                            </Row>
                                          </Form>
                                        </ModalBody>
                                      </Modal>
                                    </Td>
                                  </Tr>
                                ) : null}
                              </Fragment>
                            );
                          })}
                      </Tbody>
                    </Table>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>
        </>
      </div>
    );
  }
}
export function mapStateToProps(state) {
  return {
    uid: state.auth.uid,
    user: state.auth.user,
    restaurantId: state.auth.restaurant.id,
    menu: state.auth.menu,
    loading: state.auth.requested,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    getUser: (user) => dispatch(getUser(user)),
    getMenu: (user) => dispatch(getMenu(user)),
    getRestaurant: (user) => dispatch(getRestaurant(user)),
    outOfStockChoice: (res_id, itemId, choices) =>
      dispatch(outOfStockChoice(res_id, itemId, choices)),
    outOfStockOptionalChoice: (res_id, itemId, choices) =>
      dispatch(outOfStockOptionalChoice(res_id, itemId, choices)),
    addMenuItem: (item, image, name, id) =>
      dispatch(addMenuItem(item, image, name, id)),
    editMenuItem: (item, image, name, id) =>
      dispatch(editMenuItem(item, image, name, id)),
    deleteMenuItem: (name, id) => dispatch(deleteMenuItem(name, id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
