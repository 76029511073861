import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import moment from "moment";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import clsx from "clsx";
import axios from "axios";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ViewOrderDetailDialogs from "./ViewOrderDetails";

const PREFIX = "OrderHistory";

const classes = {
  revenueTable: `${PREFIX}-revenueTable`,
  backdrop: `${PREFIX}-backdrop`,
  container: `${PREFIX}-container`,
  textField: `${PREFIX}-textField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme: { palette, ...theme } }) => ({
  [`& .${classes.revenueTable}`]: {
    "& small": {
      height: 15,
      width: 50,
      borderRadius: 500,
      boxShadow:
        "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
    },
    "& td": {
      borderBottom: "none",
    },
    "& td:first-child": {
      paddingLeft: "16px !important",
    },
  },

  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 500,
    color: "pink",
  },

  [`& .${classes.container}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const OrderHistory = () => {
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [ordersData, setOrdersData] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = (order) => {
    setOpen(true);
    setSelectedOrder(order);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let getData = () => {
    setLoader(true);
    axios
      .post(
        `https://us-central1-new-practice-6441a.cloudfunctions.net/getOrdersHistoryForRestaurant`,
        {
          // axios
          // .post(
          //   `http://localhost:5001/new-practice-6441a/us-central1/getOrdersHistoryForRestaurant`,
          //   {
          restaurant_id: localStorage.getItem("restaurant_id"),
          // restaurant_id: "20TH ST PIZZA",
        }
      )
      .then((res) => {
        setLoader(false);
        setOrdersData(res.data.allOrders);
      })
      .catch(() => {
        setLoader(false);
        setOrdersData([]);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDateFormat = (date) => {
    const fireBaseTime = new Date(
      date._seconds * 1000 + date._nanoseconds / 1000000
    );
    const newDate = fireBaseTime.toDateString();
    const atTime = fireBaseTime.toLocaleTimeString();
    return { date: newDate, time: atTime };
  };

  if (ordersData == null) {
    return (
      <div>
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress />
        </Backdrop>
      </div>
    );
  } else {
    return (
      <Root>
        <div className="m-sm-30">
          <Card elevation={3} className="pt-5 mb-6">
            <div className="flex justify-between items-center px-6 mb-3">
              <span className="card-title">Orders</span>
              <br></br>
            </div>
            <div className="w-full overflow-auto">
              <Table
                className={clsx(
                  "whitespace-pre min-w-400",
                  classes.revenueTable
                )}
              >
                <TableHead>
                  <TableRow>
                    <TableCell className="px-0" colSpan={1}></TableCell>
                    <TableCell className="px-0" colSpan={4}>
                      Date
                    </TableCell>
                    <TableCell className="px-0" colSpan={4}>
                      Name
                    </TableCell>
                    {/* <TableCell className="px-0" colSpan={4}>
                      Email
                    </TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {ordersData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((order, index) => (
                      <TableRow
                        key={index}
                        hover
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleClickOpen(order);
                        }}
                      >
                        <TableCell
                          className="px-0 capitalize"
                          align="left"
                          colSpan={1}
                        ></TableCell>
                        <TableCell
                          className="px-0 capitalize"
                          align="left"
                          colSpan={4}
                        >
                          {moment(
                            handleDateFormat(order.created_at).date
                          ).format("MM-DD-YYYY")}{" "}
                        </TableCell>
                        <TableCell
                          className="px-0 capitalize"
                          align="left"
                          colSpan={4}
                        >
                          {order.deliver_to.firstName
                            ? `${
                                order.deliver_to.firstName
                              } ${order?.deliver_to?.lastName?.charAt(0)}`
                            : "N/A"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <TablePagination
                className="px-4"
                rowsPerPageOptions={[5, 15, 20]}
                component="div"
                count={ordersData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
            {open === true && (
              <ViewOrderDetailDialogs
                data={selectedOrder}
                handleClose={handleClose}
              />
            )}
            <div>
              <Backdrop className={classes.backdrop} open={loader}>
                <CircularProgress />
              </Backdrop>
            </div>
          </Card>
        </div>
      </Root>
    );
  }
};

export default OrderHistory;
