import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { Redirect, Switch } from "react-router";

import { SentryRoute } from "components/SentryRoute";
import firebase from "store/firebase";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { useSelector } from "react-redux";
// import RightBar from "./components/RightSidenav/RightBar";
import ColorsContext from "./utils/context";
import "assets/scss/argon-dashboard-react.scss";

export default function App() {
  console.log("App.js Comp");
  const [colorsState, setColorsState] = useState({ sidenav: "", header: "" });

  const uid = useSelector((state) => state.auth.uid);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const { email } = user;

        Sentry.setUser({ email });
      } else {
        Sentry.setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const colors = JSON.parse(localStorage.getItem("colors"));
    if (colors) {
      setColorsState(colors);
    }
  }, []);

  const colorHandler = (key, value) => {
    let obj = { ...colorsState };
    if (key === "default") {
      obj["header"] = "";
      obj["sidenav"] = "";
    } else {
      obj[key] = value;
    }
    setColorsState(obj);
    localStorage.setItem("colors", JSON.stringify(obj));
  };

  return (
    <div>
      <ColorsContext.Provider
        value={{
          colors: colorsState,
          colorHandler: colorHandler,
        }}
      >
        {/* <RightBar /> */}
        {uid ? (
          <Switch>
            <SentryRoute
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
            <Redirect to="/admin/index" />
          </Switch>
        ) : (
          <Switch>
            <SentryRoute
              path="/"
              render={(props) => <AuthLayout {...props} />}
            />
            <Redirect to="/" />
          </Switch>
        )}
      </ColorsContext.Provider>
    </div>
  );
}
