import firebase from "../firebase";
import { v4 as uuidv4 } from "uuid";
export const getMenu = (id) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Restaurants Philadelphia")
      .doc(id)
      .collection("Restaurant_Menu")
      .orderBy("category", "asc")
      .onSnapshot((query) => {
        var tempInvitations = [];
        query.forEach((doc) => {
          tempInvitations.push({ id: doc.id, ...doc.data() });
        });
        dispatch({
          type: "GET_MENU",
          menu: tempInvitations,
        });
      });
  };
};
function toObject(arr) {
  var rv = {};
  for (var i = 0; i < arr.length; ++i) {
    rv[arr[i].key] = arr[i].value;
  }
  return rv;
}
export const addMenuItem = (item, image, name, id) => {
  return async (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });

    var url = "";
    if (image) {
      var data = await firebase
        .storage()
        .ref()
        .child("test_storage/" + uuidv4() + "." + image.name.split(".").pop())
        .put(image);
      url = await data.ref.getDownloadURL();
    }
    let choices = toObject(item.choices);
    let optionalChoices = toObject(item.optionalChoices);

    firebase
      .firestore()
      .collection("Restaurants Philadelphia")
      .doc(id)
      .collection("Restaurant_Menu")
      .doc(name)
      .set({
        ...item,
        outOfStock: false,
        picture: url,
        choices,
        optionalChoices,
      })
      .then((query) => {
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        dispatch({
          type: "ADD_MENU_ITEM",
        });
      });
  };
};
export const editMenuItem = (item, image, name, id) => {
  return async (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });
    var url = "";
    var us = await firebase
      .firestore()
      .collection("Restaurants Philadelphia")
      .doc(id)
      .collection("Restaurant_Menu")
      .doc(name)
      .get();
    if (us.exists) {
      url = us.data().picture;
    }
    if (image) {
      if (url) {
        await firebase.storage().refFromURL(url).delete();
      }
      var data = await firebase
        .storage()
        .ref()
        .child("test_storage/" + uuidv4() + "." + image.name.split(".").pop())
        .put(image);
      url = await data.ref.getDownloadURL();
    }
    let choices = toObject(item.choices);
    let optionalChoices = toObject(item.optionalChoices);
    firebase
      .firestore()
      .collection("Restaurants Philadelphia")
      .doc(id)
      .collection("Restaurant_Menu")
      .doc(name)
      .set(
        {
          ...item,
          picture: url,
          choices,
          optionalChoices,
        },
        { merge: true }
      )
      .then((query) => {
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        dispatch({
          type: "EDIT_MENU_ITEM",
        });
      });
  };
};
export const outOfStockChoice = (res_id, itemId, choices) => {
  // let choices = toObject(newChoices);
  return async (dispatch) => {
    firebase
      .firestore()
      .collection("Restaurants Philadelphia")
      .doc(res_id)
      .collection("Restaurant_Menu")
      .doc(itemId)
      .update({ choices })
      .then((query) => {
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        dispatch({
          type: "EDIT_MENU_ITEM",
        });
      });
  };
};
export const outOfStockOptionalChoice = (res_id, itemId, optionalChoices) => {
  return async (dispatch) => {
    firebase
      .firestore()
      .collection("Restaurants Philadelphia")
      .doc(res_id)
      .collection("Restaurant_Menu")
      .doc(itemId)
      .update({ optionalChoices })
      .then((query) => {
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        dispatch({
          type: "EDIT_MENU_ITEM",
        });
      });
  };
};

// Please don't uncomment or remove this function ...
// export const deleteMenuItem = (names, id) => {
//   return async (dispatch) => {
//     for (let i = 0; i < names.length; i++) {
//       var us = await firebase
//         .firestore()
//         .collection("Restaurants Philadelphia")
//         .doc(id)
//         .collection("Restaurant_Menu")
//         .doc(names[i])
//         .get();
//       if (us.data().picture) {
//         await firebase.storage().refFromURL(us.data().picture).delete();
//       }
//       firebase
//         .firestore()
//         .collection("Restaurants Philadelphia")
//         .doc(id)
//         .collection("Restaurant_Menu")
//         .doc(names[i])
//         .delete()
//         .then((query) => {
//           if (i === names.length - 1) {
//             window.location.reload();
//           }
//           dispatch({
//             type: "DELETE_MENU_ITEM",
//           });
//         });
//     }
//   };
// };

export const deleteMenuItem = (name, id) => {
  return async (dispatch) => {
    var us = await firebase
      .firestore()
      .collection("Restaurants Philadelphia")
      .doc(id)
      .collection("Restaurant_Menu")
      .doc(name)
      .get();
    if (us.data().picture) {
      await firebase.storage().refFromURL(us.data().picture).delete();
    }
    firebase
      .firestore()
      .collection("Restaurants Philadelphia")
      .doc(id)
      .collection("Restaurant_Menu")
      .doc(name)
      .delete()
      .then((query) => {
        window.location.reload();
        dispatch({
          type: "DELETE_MENU_ITEM",
        });
      });
  };
};
