import firebase from "../firebase";
export const login = (credentials) => {
  return (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((data) => {
        firebase
          .firestore()
          .collection("users")
          .doc(data.user.uid)
          .onSnapshot((doc) => {
            var tempUser = {};
            tempUser = { id: doc.id, ...doc.data() };
            localStorage.setItem("restaurant_id", tempUser.restaurant);
            dispatch({
              type: "LOGIN_SUCCESS",
              user: tempUser,
              error: "",
            });
            dispatch({
              type: "ACTION_REQUEST_END",
            });
          });
      })

      .catch((error) => {
        dispatch({
          type: "LOGIN_FAIL",
          uid: "",
          error: error,
        });
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        alert(error, "danger");
      });
  };
};
export const logout = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signOut()
      .then((data) => {
        dispatch({
          type: "LOGOUT_SUCCESS",
          uid: "",
          error: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: "LOGOUT_FAIL",
          uid: "",
          error: error,
        });
      });

    // Reset the `auth.requested` redux state property in case it was previously
    // set to `true` and not set back to `false`
    dispatch({
      type: "ACTION_REQUEST_END",
    });
  };
};

export const removeAuthError = () => {
  return (dispatch) => {
    dispatch({
      type: "AUTH_RESET",
      uid: "",
      error: "",
    });
  };
};
export const registeredComplete = () => {
  return (dispatch) => {
    dispatch({
      type: "REGISTER_COMPLETE",
    });
    // Reset the `auth.requested` redux state property in case it was previously
    // set to `true` and not set back to `false`
    dispatch({
      type: "ACTION_REQUEST_END",
    });
  };
};
export const register = (credentials, password) => {
  return async (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });

    var docExist = await firebase
      .firestore()
      .collection("Restaurants Philadelphia")
      .doc(credentials.restaurant)
      .get();
    if (docExist.exists) {
      dispatch({ type: "ACTION_REQUEST_END" });
      alert("Restaurant with same name exists. Change name to Signup");
    } else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(credentials.email, password)
        .then((data) => {
          firebase
            .firestore()
            .collection("users")
            .doc(data.user.uid)
            .set({
              ...credentials,
              // favorites: [],
            })
            .then(async (res) => {
              await firebase
                .firestore()
                .collection("Restaurants Philadelphia")
                .doc(credentials.restaurant)
                .set({
                  Address: "",
                  Latitude: "0",
                  Longitude: "0",
                  Cuisine: "",
                  OpenDays: "",
                  OpenHours: "",
                  Phone: credentials.phone,
                  Restaurant: credentials.restaurant,
                  Website: "",
                  Image: "",
                  isDeliveryAvailable: false,
                  isPickUpAvailable: false,
                })
                .then((query) => {
                  dispatch({
                    type: "ACTION_REQUEST_END",
                  });
                  dispatch({
                    type: "CREATE_RESTAURANT",
                  });
                });
              dispatch({
                type: "REGISTER_SUCCESS",
              });
              dispatch({
                type: "ACTION_REQUEST_END",
              });
              alert("Account Created Successfully");
            })
            .catch((err) => {
              dispatch({
                type: "REGISTER_FAIL",
              });
              dispatch({
                type: "ACTION_REQUEST_END",
              });
              alert(err, "danger");
            });
        })
        .catch((err) => {
          dispatch({
            type: "REGISTER_FAIL",
          });
          dispatch({
            type: "ACTION_REQUEST_END",
          });
          alert(err, "danger");
        });
    }
  };
};

export const recoverAccount = (email) => {
  return (dispatch) => {
    dispatch({
      type: "ACTION_REQUEST",
    });
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((res) => {
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        dispatch({
          type: "ACCOUNT_RECOVER",
        });
        alert("Email Sent for Account Recovery");
      })
      .catch((err) => {
        dispatch({
          type: "ACCOUNT_RECOVER_FAIL",
        });
      });
  };
};
