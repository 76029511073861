const initState = {
  authError: "",
  uid: "",
  requested: false,
  registered: false,
  user: null,
  restaurant: null,
  menu: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        uid: action.user.id,
        user: { ...action.user },
        authError: "",
      };
    case "GOOGLE_LOGIN_SUCCESS":
      return {
        ...state,
        uid: action.user.id,
        user: { ...action.user },
        authError: "",
      };
    case "GOOGLE_SIGNUP_SUCCESS":
      return {
        ...state,
        uid: action.user.id,
        user: { ...action.user },
        authError: "",
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        uid: "",
        authError: action.error.message,
      };
    case "REGISTER_SUCCESS":
      return {
        ...state,
        uid: "",
        authError: "",
        registered: true,
      };
    case "ACCOUNT_RECOVER":
      return {
        ...state,
        uid: "",
        authError: "",
        registered: true,
      };

    case "REGISTER_FAIL":
      return {
        ...state,
        uid: "",
      };
    case "REGISTER_COMPLETE":
      return {
        ...state,
        registered: false,
      };

    case "LOGOUT_SUCCESS":
      return {
        ...state,
        authError: "",
        uid: "",
        user: null,
        menu: null,
        restaurant: null,
      };
    case "AUTH_RESET":
      return {
        ...state,
        authError: "",
      };
    case "GET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "GET_MENU":
      return {
        ...state,
        menu: action.menu,
      };
    case "ACTION_REQUEST":
      return {
        ...state,
        requested: true,
      };
    case "ACTION_REQUEST_END":
      return {
        ...state,
        requested: false,
      };
    case "GET_RESTAURANT":
      return {
        ...state,
        restaurant: action.restaurant,
      };
    default:
      return state;
  }
};

export default authReducer;
