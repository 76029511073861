import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import moment from "moment";
import {
  Grid,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  Button,
} from "@mui/material";
import clsx from "clsx";
import axios from "axios";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const PREFIX = "LoyaltyVisits";

const classes = {
  revenueTable: `${PREFIX}-revenueTable`,
  backdrop: `${PREFIX}-backdrop`,
  container: `${PREFIX}-container`,
  textField: `${PREFIX}-textField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme: { palette, ...theme } }) => ({
  [`& .${classes.revenueTable}`]: {
    "& small": {
      height: 15,
      width: 50,
      borderRadius: 500,
      boxShadow:
        "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
    },
    "& td": {
      borderBottom: "none",
    },
    "& td:first-child": {
      paddingLeft: "16px !important",
    },
  },

  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 500,
    color: "pink",
  },

  [`& .${classes.container}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const LoyaltyVisits = () => {
  const [page, setPage] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const [open, setOpen] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [LoyaltyVisitsData, setLoyaltyVisitsData] = React.useState(null);
  const [totalLoyaltyVisits, setTotalLoyaltyVisits] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const handleSelectToDate = (e) => {
    setToDate(e.target.value);
  };

  let getData = (initialDate, finalDate) => {
    setLoader(true);
    axios
      .post(
        `https://us-central1-new-practice-6441a.cloudfunctions.net/getLoyaltyVisitsForRestaurant`,
        {
          // axios
          // .post(
          //   `http://localhost:5001/new-practice-6441a/us-central1/getLoyaltyVisitsForRestaurant`,
          //   {
          restaurantName: localStorage.getItem("restaurant_id"),
          // restaurantName: "LUCKY 13 PUB",
          fromDate: initialDate,
          toDate: finalDate,
        }
      )
      .then((res) => {
        setLoader(false);
        setLoyaltyVisitsData(res.data.loyaltyProgrammesList);
        setTotalLoyaltyVisits(res.data.totalLoyaltyVisits);
      })
      .catch((error) => {
        setLoader(false);
        setLoyaltyVisitsData([]);
        console.log("Got error");
      });
  };

  const handleFilter = () => {
    console.log("Hanlde Filter called");
    console.log(fromDate);
    console.log(toDate);
    getData(fromDate, toDate);
  };

  useEffect(() => {
    let finalDate = moment(new Date()).format("YYYY-MM-DD");
    let initialDate = new Date().setMonth(new Date().getMonth() - 1);
    initialDate = moment(initialDate).format("YYYY-MM-DD");
    setFromDate(initialDate);
    setToDate(finalDate);
    getData(initialDate, finalDate);
  }, []);

  if (LoyaltyVisitsData == null) {
    return (
      <div>
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress />
        </Backdrop>
      </div>
    );
  } else {
    return (
      <Root>
        <div className="m-sm-30">
          <Card elevation={3} className="pt-5 mb-6">
            <div className="flex justify-between items-center px-6 mb-3">
              <span className="card-title">Loyalty Visits</span>
              <br></br>
              <br></br>
              <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <form className={classes.container} noValidate>
                    <TextField
                      variant="standard"
                      id="date"
                      label="From Date"
                      type="date"
                      defaultValue={fromDate}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleSelectFromDate}
                    />
                  </form>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <form className={classes.container} noValidate>
                    <TextField
                      variant="standard"
                      id="date"
                      label="To Date"
                      type="date"
                      defaultValue={toDate}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleSelectToDate}
                    />
                  </form>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <Button
                    onClick={() => {
                      handleFilter();
                    }}
                    variant="contained"
                    // disabled
                    style={{
                      backgroundColor: "#536DFE",
                      color: "white",
                      marginTop: "10px",
                    }}
                  >
                    Filter
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className="w-full overflow-auto">
              <Table
                className={clsx(
                  "whitespace-pre min-w-400",
                  classes.revenueTable
                )}
              >
                <TableHead>
                  <TableRow>
                    <TableCell className="px-0" colSpan={1}></TableCell>
                    <TableCell className="px-0" colSpan={4}>
                      Date
                    </TableCell>
                    <TableCell className="px-0" colSpan={4}>
                      Loyalty Visits
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {LoyaltyVisitsData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((loyaltyVisit, index) => (
                    <TableRow key={index} hover>
                      <TableCell
                        className="px-0 capitalize"
                        align="left"
                        colSpan={1}
                      ></TableCell>
                      <TableCell
                        className="px-0 capitalize"
                        align="left"
                        colSpan={4}
                      >
                        {/* {loyaltyVisit.date} */}
                        {moment(loyaltyVisit.date).format("MM-DD-YYYY")}
                      </TableCell>
                      <TableCell
                        className="px-0 capitalize"
                        align="left"
                        colSpan={4}
                      >
                        {loyaltyVisit.total}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <TablePagination
                className="px-4"
                rowsPerPageOptions={[5, 15, 20]}
                component="div"
                count={LoyaltyVisitsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* <br /> */}
              <div style={{ textAlign: "center" }}>
                Total Loyalty Visits : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {totalLoyaltyVisits}
              </div>
            </div>
            <div>
              <Backdrop className={classes.backdrop} open={loader}>
                <CircularProgress />
              </Backdrop>
            </div>
          </Card>
        </div>
      </Root>
    );
  }
};

export default LoyaltyVisits;
