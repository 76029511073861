import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

export const Map = ({ zoom, centerLat, centerLng, markerLat, markerLng }) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds({
        lat: centerLat,
        lng: centerLng,
      });

      map.fitBounds(bounds);
    }
  }, [centerLat, centerLng, map]);

  return (
    <GoogleMap
      mapContainerStyle={{ minHeight: "40vh" }}
      defaultZoom={zoom}
      defaultCenter={{
        lat: centerLat,
        lng: centerLng,
      }}
      onLoad={(gmap) => {
        const bounds = new window.google.maps.LatLngBounds({
          lat: centerLat,
          lng: centerLng,
        });

        gmap.fitBounds(bounds);

        setMap(gmap);
      }}
    >
      <Marker
        position={{
          lat: markerLat,
          lng: markerLng,
        }}
      />
    </GoogleMap>
  );
};
