/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// reactstrap components
import { Navbar, Nav, Container } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { logout } from "store/actions/authActions";

export default function AdminNavbar({ brandText }) {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid>
        <Link
          className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          to="/"
        >
          {brandText}
        </Link>
        <div className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"></div>
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <Button
            onClick={(e) => {
              e.preventDefault();
              history.replace("/");
              dispatch(logout());
            }}
            size="md"
            style={{ background: "#E8C85E", color: "black" }}
          >
            Logout
          </Button>
        </Nav>
      </Container>
    </Navbar>
  );
}
