import { Map } from "components/Map";
import Header from "components/Headers/Header";
import React, { Component, Fragment } from "react";
import Button from "reactstrap/lib/Button";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import { connect } from "react-redux";
import Container from "reactstrap/lib/Container";
import Form from "reactstrap/lib/Form";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import "bootstrap/dist/css/bootstrap.css";
import { CustomInput } from "reactstrap";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Row from "reactstrap/lib/Row";
import { Col, Spinner } from "reactstrap";
import { updateUser } from "store/actions/userActions";
import { getUser } from "store/actions/userActions";
import { getMenu } from "store/actions/menuActions";
import dummyImage from "assets/img/theme/sketch.jpg";
import Geocode from "react-geocode";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { getRestaurant } from "store/actions/userActions";
import { FormGroup } from "reactstrap/lib";
Geocode.setLanguage("en");
Geocode.enableDebug();

class Profile extends Component {
  async componentDidMount() {
    var { getUser, uid, getMenu, user, getRestaurant, restaurant } = this.props;
    await getUser(uid);
    await getMenu(uid);
    await getRestaurant(user.restaurant);

    if (restaurant.waitTime) {
      this.setState({ waitTime: restaurant.waitTime });
    }
    this.setState({
      mapPosition: {
        lat: parseFloat(restaurant.Latitude),
        lng: parseFloat(restaurant.Longitude),
      },
      markerPosition: {
        lat: parseFloat(restaurant.Latitude),
        lng: parseFloat(restaurant.Longitude),
      },
    });
    ["1", "2", "3", "4", "5", "6", "7"].forEach((day) => {
      if (restaurant && restaurant.OpenDays) {
        if (restaurant.OpenDays.includes(day)) {
          if (day === "1") {
            let obj = JSON.parse(restaurant.OpenHours);
            let openHours = this.state.openHours;
            openHours.push({
              id: day,
              start: obj["1"].split("/")[0],
              end: obj["1"].split("/")[1],
            });

            this.setState({ sunday: true, openHours });
          }
          if (day === "2") {
            let obj = JSON.parse(restaurant.OpenHours);
            let openHours = this.state.openHours;
            openHours.push({
              id: day,
              start: obj["2"].split("/")[0],
              end: obj["2"].split("/")[1],
            });
            this.setState({ monday: true, openHours });
          }
          if (day === "3") {
            let obj = JSON.parse(restaurant.OpenHours);
            let openHours = this.state.openHours;
            openHours.push({
              id: day,
              start: obj["3"].split("/")[0],
              end: obj["3"].split("/")[1],
            });
            this.setState({ tuesday: true, openHours });
          }
          if (day === "4") {
            let obj = JSON.parse(restaurant.OpenHours);
            let openHours = this.state.openHours;
            openHours.push({
              id: day,
              start: obj["4"].split("/")[0],
              end: obj["4"].split("/")[1],
            });
            this.setState({ wednesday: true, openHours });
          }
          if (day === "5") {
            let obj = JSON.parse(restaurant.OpenHours);
            let openHours = this.state.openHours;
            openHours.push({
              id: day,
              start: obj["5"].split("/")[0],
              end: obj["5"].split("/")[1],
            });
            this.setState({ thursday: true, openHours });
          }
          if (day === "6") {
            let obj = JSON.parse(restaurant.OpenHours);
            let openHours = this.state.openHours;
            openHours.push({
              id: day,
              start: obj["6"].split("/")[0],
              end: obj["6"].split("/")[1],
            });
            this.setState({ friday: true, openHours });
          }
          if (day === "7") {
            let obj = JSON.parse(restaurant.OpenHours);
            let openHours = this.state.openHours;
            openHours.push({
              id: day,
              start: obj["7"].split("/")[0],
              end: obj["7"].split("/")[1],
            });
            this.setState({ saturday: true, openHours });
          }
        }
      }
    });

    setTimeout(() => {
      this.setState({ isvisible: true });
    }, 3000);
  }
  state = {
    modal: "",
    monday: false,
    isvisible: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    deliveryChangeSchedule: {},
    deliveryChangeModal: false,
    pickupChangeSchedule: {},
    pickupChangeModal: false,
    deliveryChangeHours: 0,
    pickupChangeHours: 0,
    isDeliveryAvailable: null,
    isPickUpAvailable: null,
    openHours: [],
    loader : false,
    mapPosition: {
      lat: 73,
      lng: 73,
    },
    markerPosition: {
      lat: 73 + 0.0118,
      lng: 73,
    },
    address: "",
    selectedAddress: "Los Angeles,USA",
    waitTime: 30,
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleChangeWaitTime = (e) => {
    this.setState({ waitTime: e.target.value });
  };

  handleResponse = () => {
    console.log("Loader stopped");
    console.log("Loader stopped");
    console.log("Loader stopped");
    console.log("Loader stopped");
    console.log("Loader stopped");
    this.setState({ loader: false });
  };

  handleChangeDelivery = (available) => {
    if (this.state.isDeliveryAvailable != null) {
      if (this.state.isDeliveryAvailable) {
        this.setState({
          isDeliveryAvailable: !this.state.isDeliveryAvailable,
        });
        this.setState({ deliveryChangeModal: !this.state.deliveryChangeModal });
      } else {
        this.setState({
          isDeliveryAvailable: !this.state.isDeliveryAvailable,
        });
      }
    } else {
      if (available) {
        this.setState({
          isDeliveryAvailable: false,
        });
        this.setState({ deliveryChangeModal: !this.state.deliveryChangeModal });
      } else {
        this.setState({
          isDeliveryAvailable: true,
        });
      }
    }
  };

  handleChangePickup = (available) => {
    if (this.state.isPickUpAvailable != null) {
      if (this.state.isPickUpAvailable) {
        this.setState({
          isPickUpAvailable: !this.state.isPickUpAvailable,
        });
        this.setState({ pickupChangeModal: !this.state.pickupChangeModal });
      } else {
        this.setState({
          isPickUpAvailable: !this.state.isPickUpAvailable,
        });
      }
    } else {
      if (available) {
        this.setState({
          isPickUpAvailable: false,
        });
        this.setState({ pickupChangeModal: !this.state.pickupChangeModal });
      } else {
        this.setState({
          isPickUpAvailable: true,
        });
      }
    }
  };

  handleChangeDeliveryHours = (e) => {
    this.setState({
      deliveryChangeHours: e.target.value * 1,
    });
  };

  handleChangePickupHours = (e) => {
    this.setState({
      pickupChangeHours: e.target.value * 1,
    });
  };

  handleChangeDeliverySchedule = (isCancelled) => {
    if (isCancelled) {
      let schedule = {
        hours: 0,
      };
      this.setState({
        deliveryChangeSchedule: schedule,
      });
    } else {
      let schedule = {
        hours: this.state.deliveryChangeHours,
      };
      this.setState({
        deliveryChangeSchedule: schedule,
      });
    }
    this.setState({
      deliveryChangeModal: false,
    });
  };

  handleChangePickupSchedule = (isCancelled) => {
    if (isCancelled) {
      let schedule = {
        hours: 0,
      };
      this.setState({
        pickupChangeSchedule: schedule,
      });
    } else {
      let schedule = {
        hours: this.state.pickupChangeHours,
      };
      this.setState({
        pickupChangeSchedule: schedule,
      });
    }

    this.setState({
      pickupChangeModal: false,
    });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) =>
        this.setState({
          mapPosition: { lat: latLng.lat, lng: latLng.lng },
          markerPosition: { lat: latLng.lat, lng: latLng.lng },
          selectedAddress: address,
        })
      )
      .catch((error) => console.error("Error", error));
  };

  render() {
    var { restaurant } = this.props;

    return (
      <div>
        <>
          <Header />
          <Container className="mt--9" fluid>
            <br />
            <br />

            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <span className="mb-0 text-warning">Restaurant Info</span>
                  </CardHeader>
                  <CardBody>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.setState({ loader: true });
                        var days = "";
                        [1, 2, 3, 4, 5, 6, 7].forEach((day) => {
                          if (day === 1) {
                            if (this.state.sunday) {
                              days = days + "1";
                            }
                          }
                          if (day === 2) {
                            if (this.state.monday) {
                              if (days) {
                                days = days + ",2";
                              } else {
                                days = days + "2";
                              }
                            }
                          }
                          if (day === 3) {
                            if (this.state.tuesday) {
                              if (days) {
                                days = days + ",3";
                              } else {
                                days = days + "3";
                              }
                            }
                          }
                          if (day === 4) {
                            if (this.state.wednesday) {
                              if (days) {
                                days = days + ",4";
                              } else {
                                days = days + "4";
                              }
                            }
                          }
                          if (day === 5) {
                            if (this.state.thursday) {
                              if (days) {
                                days = days + ",5";
                              } else {
                                days = days + "5";
                              }
                            }
                          }
                          if (day === 6) {
                            if (this.state.friday) {
                              if (days) {
                                days = days + ",6";
                              } else {
                                days = days + "6";
                              }
                            }
                          }
                          if (day === 7) {
                            if (this.state.saturday) {
                              if (days) {
                                days = days + ",7";
                              } else {
                                days = days + "7";
                              }
                            }
                          }
                        });
                        let openHours = {};
                        if (this.state.openHours.length) {
                          this.state.openHours.forEach((day) => {
                            if (day.id === "1") {
                              openHours = {
                                ...openHours,
                                1: `${day.start}/${day.end}`,
                              };
                            }
                            if (day.id === "2") {
                              openHours = {
                                ...openHours,
                                2: `${day.start}/${day.end}`,
                              };
                            }
                            if (day.id === "3") {
                              openHours = {
                                ...openHours,
                                3: `${day.start}/${day.end}`,
                              };
                            }
                            if (day.id === "4") {
                              openHours = {
                                ...openHours,
                                4: `${day.start}/${day.end}`,
                              };
                            }
                            if (day.id === "5") {
                              openHours = {
                                ...openHours,
                                5: `${day.start}/${day.end}`,
                              };
                            }
                            if (day.id === "6") {
                              openHours = {
                                ...openHours,
                                6: `${day.start}/${day.end}`,
                              };
                            }
                            if (day.id === "7") {
                              openHours = {
                                ...openHours,
                                7: `${day.start}/${day.end}`,
                              };
                            }
                          });
                        }
                        openHours = JSON.stringify(openHours, null, " ");
                        this.props.updateUser(
                          {
                            Address: e.target.address.value,
                            Latitude: this.state.markerPosition.lat.toString(),
                            Longitude: this.state.markerPosition.lng.toString(),
                            Cuisine: e.target.cuisine.value,
                            OpenDays: days,
                            OpenHours: openHours,
                            Phone: e.target.phone.value,
                            Restaurant: e.target.restaurant.value,
                            Website: e.target.website.value,
                            isDeliveryAvailable: e.target.delivery.checked,
                            isPickUpAvailable: e.target.pickup.checked,
                            waitTime: this.state.waitTime * 1,
                          },
                          e.target.image.files[0],
                          this.props.restaurant.id,
                          this.state.deliveryChangeSchedule,
                          this.state.pickupChangeSchedule,
                          this.handleResponse
                        );
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                        }}
                      >
                        <img
                          alt=""
                          src={
                            restaurant && restaurant.Image
                              ? restaurant.Image
                              : dummyImage
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <br />
                      <Label>Profile Image</Label>
                      <Input name="image" type="file" className="mb-2" />
                      {/* Map */}
                      <div>
                        <div className="form-group text-left">
                          <PlacesAutocomplete
                            className="autocomplete-signup"
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <Input
                                  {...getInputProps({
                                    placeholder:
                                      "Search Place to set Lat & Lng",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <br />
                          <Map
                            centerLat={this.state.mapPosition.lat}
                            centerLng={this.state.mapPosition.lng}
                            zoom={18}
                            markerLat={this.state.markerPosition.lat}
                            markerLng={this.state.markerPosition.lng}
                          />
                        </div>
                      </div>
                      {/* Map Finish */} <Label>Latitude</Label>
                      <Input
                        name="latitude"
                        type="text"
                        required
                        readOnly
                        value={this.state.markerPosition.lat}
                      />
                      <Label>Longitude</Label>
                      <Input
                        name="longitude"
                        type="text"
                        required
                        readOnly
                        value={this.state.markerPosition.lng}
                      />
                      <Label>Address</Label>
                      <Input
                        name="address"
                        type="text"
                        required
                        defaultValue={restaurant && restaurant.Address}
                      />
                      <Label>Cuisine</Label>
                      <Input
                        name="cuisine"
                        type="text"
                        required
                        defaultValue={restaurant && restaurant.Cuisine}
                      />
                      <Label>Open Days</Label>
                      <div>
                        <input
                          type="checkbox"
                          checked={this.state.sunday}
                          onChange={(e) => {
                            let openHours = this.state.openHours;
                            let hoursExist = openHours.find(
                              (op) => op.id === "1"
                            );
                            if (hoursExist) {
                              openHours = openHours.filter(
                                (op) => op.id !== "1"
                              );
                            } else {
                              openHours.push({
                                id: "1",
                                start: "00:00",
                                end: "23:59",
                              });
                            }
                            this.setState({
                              sunday: !this.state.sunday,
                              openHours,
                            });
                          }}
                          id="sunday"
                        />
                        &nbsp;
                        <label htmlFor="sunday">Sunday</label>
                        <input
                          type="checkbox"
                          className="ml-3"
                          checked={this.state.monday}
                          onChange={(e) => {
                            let openHours = this.state.openHours;
                            let hoursExist = openHours.find(
                              (op) => op.id === "2"
                            );
                            if (hoursExist) {
                              openHours = openHours.filter(
                                (op) => op.id !== "2"
                              );
                            } else {
                              openHours.push({
                                id: "2",
                                start: "00:00",
                                end: "23:59",
                              });
                            }
                            this.setState({
                              monday: !this.state.monday,
                              openHours,
                            });
                          }}
                          id="monday"
                        />
                        &nbsp;
                        <label htmlFor="monday">Monday</label>
                        <input
                          type="checkbox"
                          className="ml-3"
                          checked={this.state.tuesday}
                          onChange={(e) => {
                            let openHours = this.state.openHours;
                            let hoursExist = openHours.find(
                              (op) => op.id === "3"
                            );
                            if (hoursExist) {
                              openHours = openHours.filter(
                                (op) => op.id !== "3"
                              );
                            } else {
                              openHours.push({
                                id: "3",
                                start: "00:00",
                                end: "23:59",
                              });
                            }
                            this.setState({
                              tuesday: !this.state.tuesday,
                              openHours,
                            });
                          }}
                          id="tuesday"
                        />
                        &nbsp;
                        <label htmlFor="tuesday">Tuesday</label>
                        <input
                          type="checkbox"
                          className="ml-3"
                          checked={this.state.wednesday}
                          onChange={(e) => {
                            let openHours = this.state.openHours;
                            let hoursExist = openHours.find(
                              (op) => op.id === "4"
                            );
                            if (hoursExist) {
                              openHours = openHours.filter(
                                (op) => op.id !== "4"
                              );
                            } else {
                              openHours.push({
                                id: "4",
                                start: "00:00",
                                end: "23:59",
                              });
                            }
                            this.setState({
                              wednesday: !this.state.wednesday,
                              openHours,
                            });
                          }}
                          id="wednesday"
                        />
                        &nbsp;
                        <label htmlFor="wednesday">Wednesday</label>
                        <input
                          type="checkbox"
                          className="ml-3"
                          checked={this.state.thursday}
                          onChange={(e) => {
                            let openHours = this.state.openHours;
                            let hoursExist = openHours.find(
                              (op) => op.id === "5"
                            );
                            if (hoursExist) {
                              openHours = openHours.filter(
                                (op) => op.id !== "5"
                              );
                            } else {
                              openHours.push({
                                id: "5",
                                start: "00:00",
                                end: "23:59",
                              });
                            }
                            this.setState({
                              thursday: !this.state.thursday,
                              openHours,
                            });
                          }}
                          id="thursday"
                        />
                        &nbsp;
                        <label htmlFor="thursday">Thursday</label>
                        <input
                          type="checkbox"
                          className="ml-3"
                          checked={this.state.friday}
                          onChange={(e) => {
                            let openHours = this.state.openHours;
                            let hoursExist = openHours.find(
                              (op) => op.id === "6"
                            );
                            if (hoursExist) {
                              openHours = openHours.filter(
                                (op) => op.id !== "6"
                              );
                            } else {
                              openHours.push({
                                id: "6",
                                start: "00:00",
                                end: "23:59",
                              });
                            }
                            this.setState({
                              friday: !this.state.friday,
                              openHours,
                            });
                          }}
                          id="friday"
                        />
                        &nbsp;
                        <label htmlFor="friday">Friday</label>
                        <input
                          type="checkbox"
                          className="ml-3"
                          checked={this.state.saturday}
                          onChange={(e) => {
                            let openHours = this.state.openHours;
                            let hoursExist = openHours.find(
                              (op) => op.id === "7"
                            );
                            if (hoursExist) {
                              openHours = openHours.filter(
                                (op) => op.id !== "7"
                              );
                            } else {
                              openHours.push({
                                id: "7",
                                start: "00:00",
                                end: "23:59",
                              });
                            }
                            this.setState({
                              saturday: !this.state.saturday,
                              openHours,
                            });
                          }}
                          id="saturday"
                        />
                        &nbsp;
                        <label htmlFor="saturday">Saturday</label>
                      </div>
                      <Label>Open Hours</Label>
                      {this.state.openHours.length ? (
                        this.state.openHours
                          .sort((a, b) => {
                            if (a.id > b.id) {
                              return 1;
                            } else {
                              if (a.id < b.id) {
                                return -1;
                              } else {
                                return 0;
                              }
                            }
                          })
                          .map((day) => {
                            return (
                              <Fragment key={day.id}>
                                <h3>
                                  {day.id === "2"
                                    ? "Monday"
                                    : day.id === "3"
                                    ? "Tuesday"
                                    : day.id === "4"
                                    ? "Wednesday"
                                    : day.id === "5"
                                    ? "Thursday"
                                    : day.id === "6"
                                    ? "Friday"
                                    : day.id === "7"
                                    ? "Saturday"
                                    : "Sunday"}
                                </h3>
                                <Row>
                                  <Col>
                                    <h5>Start Time</h5>
                                    <Input
                                      type="time"
                                      value={
                                        this.state.openHours.find(
                                          (d) => d.id === day.id
                                        )
                                          ? this.state.openHours.find(
                                              (d) => d.id === day.id
                                            ).start
                                          : ""
                                      }
                                      onChange={(e) => {
                                        let openHours = this.state.openHours;
                                        let requiredDayIndex =
                                          openHours.findIndex(
                                            (d) => d.id === day.id
                                          );
                                        let requiredDay = openHours.find(
                                          (d) => d.id === day.id
                                        );
                                        requiredDay.start = e.target.value;
                                        openHours[requiredDayIndex] =
                                          requiredDay;
                                        this.setState({ openHours });
                                      }}
                                    />
                                  </Col>
                                  <Col>
                                    <h5>End Time</h5>
                                    <Input
                                      type="time"
                                      value={
                                        this.state.openHours.find(
                                          (d) => d.id === day.id
                                        )
                                          ? this.state.openHours.find(
                                              (d) => d.id === day.id
                                            ).end
                                          : ""
                                      }
                                      onChange={(e) => {
                                        let openHours = this.state.openHours;
                                        let requiredDayIndex =
                                          openHours.findIndex(
                                            (d) => d.id === day.id
                                          );
                                        let requiredDay = openHours.find(
                                          (d) => d.id === day.id
                                        );
                                        requiredDay.end = e.target.value;
                                        if (
                                          requiredDay.end > requiredDay.start
                                        ) {
                                          openHours[requiredDayIndex] =
                                            requiredDay;
                                          this.setState({ openHours });
                                        } else {
                                          alert(
                                            "Closing Time should be Greater than Opening Time"
                                          );
                                        }
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Fragment>
                            );
                          })
                      ) : (
                        <h3>Select open days to set open hours</h3>
                      )}
                      {this.state.openHours.length ? null : <br />}
                      <Label>Phone</Label>
                      <Input
                        name="phone"
                        type="text"
                        required
                        defaultValue={restaurant && restaurant.Phone}
                      />
                      <Label>Restaurant</Label>
                      <Input
                        name="restaurant"
                        type="text"
                        required
                        readOnly
                        defaultValue={restaurant && restaurant.Restaurant}
                      />
                      <Label>Website</Label>
                      <Input
                        name="website"
                        type="text"
                        required
                        defaultValue={restaurant && restaurant.Website}
                      />
                      <Label>Wait Time</Label>
                      <Input
                        type="select"
                        name="waitTime"
                        id="waitTime"
                        onChange={this.handleChangeWaitTime}
                      >
                        <option
                          selected={
                            this.state.waitTime * 1 === 30 ? true : false
                          }
                          value={30}
                        >
                          30 minutes
                        </option>
                        <option
                          selected={
                            this.state.waitTime * 1 === 35 ? true : false
                          }
                          value={35}
                        >
                          35 minutes
                        </option>
                        <option
                          selected={
                            this.state.waitTime * 1 === 40 ? true : false
                          }
                          value={40}
                        >
                          40 minutes
                        </option>
                        <option
                          selected={
                            this.state.waitTime * 1 === 50 ? true : false
                          }
                          value={50}
                        >
                          50 minutes
                        </option>
                        <option
                          selected={
                            this.state.waitTime * 1 === 60 ? true : false
                          }
                          value={60}
                        >
                          60 minutes
                        </option>
                        <option
                          selected={
                            this.state.waitTime * 1 === 70 ? true : false
                          }
                          value={70}
                        >
                          70 minutes
                        </option>
                      </Input>
                      <FormGroup className="mt-3">
                        <Label>Other</Label>
                        <div>
                          <CustomInput
                            type="switch"
                            id="isDeliveryAvailable"
                            name="delivery"
                            label="Delivery Available"
                            defaultChecked={
                              restaurant && restaurant.isDeliveryAvailable
                            }
                            onChange={() => {
                              this.handleChangeDelivery(
                                restaurant?.isDeliveryAvailable
                              );
                            }}
                          />
                          <CustomInput
                            type="switch"
                            id="isPickUpAvailable"
                            name="pickup"
                            label="Pickup Available"
                            defaultChecked={
                              restaurant && restaurant.isPickUpAvailable
                            }
                            onChange={() => {
                              this.handleChangePickup(
                                restaurant?.isPickUpAvailable
                              );
                            }}
                          />
                        </div>
                      </FormGroup>
                      <Row>
                        <Col className="text-right mt-2">
                          <Button color="success">
                          {this.state.loader ? <Spinner size="sm" /> : "Save"}
                            </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>
        </>
        <Modal
          isOpen={this.state.deliveryChangeModal}
          toggle={() => {
            this.setState({ deliveryChangeModal: false });
          }}
          className={this.props.className}
        >
          <ModalHeader
            toggle={() => {
              this.setState({ deliveryChangeModal: false });
            }}
          >
            How many hours would you like delivery turned off for?
          </ModalHeader>
          <ModalBody>
            <Label>Hours</Label>
            <Input
              name="changeDeliveryHours"
              type="number"
              onChange={this.handleChangeDeliveryHours}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.handleChangeDeliverySchedule(false);
              }}
            >
              Continue
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                this.handleChangeDeliverySchedule(true);
              }}
            >
              Indefinitely
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.pickupChangeModal}
          toggle={() => {
            this.setState({ pickupChangeModal: false });
          }}
          className={this.props.className}
        >
          <ModalHeader
            toggle={() => {
              this.setState({ pickupChangeModal: false });
            }}
          >
            How many hours would you like pickup turned off for?
          </ModalHeader>
          <ModalBody>
            <Label>Hours</Label>
            <Input
              name="changeDeliveryHours"
              type="number"
              onChange={this.handleChangePickupHours}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.handleChangePickupSchedule(false);
              }}
            >
              Continue
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                this.handleChangePickupSchedule(true);
              }}
            >
              Indefinitely
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    uid: state.auth.uid,
    user: state.auth.user,
    restaurant: state.auth.restaurant,
    loading: state.auth.requested,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    updateUser: (
      user,
      image,
      uid,
      deliveryChangeSchedule,
      pickupChangeSchedule,
      handleResponse
    ) =>
      dispatch(
        updateUser(
          user,
          image,
          uid,
          deliveryChangeSchedule,
          pickupChangeSchedule,
          handleResponse
        )
      ),
    getUser: (user) => dispatch(getUser(user)),
    getRestaurant: (Restaurant) => dispatch(getRestaurant(Restaurant)),
    getMenu: (user) => dispatch(getMenu(user)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
