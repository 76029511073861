/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { register } from "store/actions/authActions";

class Register extends React.Component {
  render() {
    var { loading, registered } = this.props;
    if (registered) {
      return <Redirect to="/" />;
    }
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Register</small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form
                role="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.props.register(
                    {
                      email: e.target.email.value,
                      phone: e.target.phone.value,
                      restaurant: e.target.restaurant.value.toUpperCase(),
                      timeStamp: moment().format(),
                    },
                    e.target.password.value
                  );
                }}
              >
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      required
                      name="email"
                      autoComplete="new-email"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone-alt" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Phone"
                      type="number"
                      required
                      name="phone"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-store" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Restaurant"
                      type="text"
                      required
                      name="restaurant"
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      required
                      name="password"
                      autoComplete="new-password"
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button disabled={loading} className="my-4" color="primary">
                    {loading ? <Spinner size="sm" /> : "Register"}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <Link to="/">
                {" "}
                <small className="white">Already have an account?</small>{" "}
              </Link>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export function mapStateToProps(state) {
  return {
    // auth: state.auth,
    uid: state.auth.uid,
    loading: state.auth.requested,
    registered: state.auth.registered,
    // cars: state.cars.cars,
    // user: state.auth.user,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    // getCars: () => dispatch(getCars()),
    // getUser: (uid) => dispatch(getUser(uid)),
    // viewCar: (car) => dispatch(viewCar(car)),
    // addFav: (fav) => dispatch(addFav(fav)),
    // removeFav: (fav) => dispatch(removeFav(fav)),
    register: (credentials, password) =>
      dispatch(register(credentials, password)),
    // register: (credentials, password) =>
    // dispatch(register(credentials, password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
