/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ColorsContext from "./../../utils/context";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class Header extends React.Component {
  static contextType = ColorsContext;
  render() {
    return (
      <>
        {this.context.colors.header === "" ? (
          <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col lg="6" xl="3"></Col>
                  <Col lg="6" xl="3"></Col>
                </Row>
              </div>
            </Container>
          </div>
        ) : (
          <div
            className="header pb-8 pt-5 pt-md-8"
            style={{ background: this.context.colors.header }}
          >
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col lg="6" xl="3"></Col>
                  <Col lg="6" xl="3"></Col>
                </Row>
              </div>
            </Container>
          </div>
        )}
      </>
    );
  }
}

export default Header;
