import React, { useEffect } from "react";

import { Card, CardBody, CardTitle, CardText } from "reactstrap";

export const Fallback = () => {
  useEffect(() => {
    // Since a crash could be the result of persistent bad data in localStorage,
    // clear everything from localStorage so that when the user refreshes the
    // page, they will not see the error again.
    localStorage.removeItem("auth");
    localStorage.removeItem("restaurant_id");
  }, []);

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <Card
        outline={true}
        className="mw-100"
        style={{ width: 450, margin: 18 }}
      >
        <CardBody>
          <iframe
            frameBorder="0"
            style={{
              margin: "auto",
              height: 290,
              display: "block",
              pointerEvents: "none",
              border: "1px solid white",
              padding: 0,
            }}
            title="turtle animation"
            src="https://embed.lottiefiles.com/animation/33024"
          ></iframe>
          <CardTitle className="offerheading" style={{ marginTop: -50 }}>
            Oh no...
          </CardTitle>
          <CardText className="menusubheading">
            Something went wrong. We're working on fixing the issue, but until
            then, please refresh the page and try again.
          </CardText>
          <small style={{ color: "rgb(190, 190, 190)" }}>
            Animation by{" "}
            <a
              target="_blank"
              style={{ color: "rgb(190, 190, 190)" }}
              href="https://lottiefiles.com/user/481665"
              rel="noreferrer noopener"
            >
              Mayer Estinville
            </a>
          </small>
        </CardBody>
      </Card>
    </div>
  );
};
