import { Index } from "views/Index.js";

// import Spaces from './views/examples/Spaces'

import Profile from "views/examples/Profile.js";
import Menu from "views/examples/Menu.js";
import Performance from "views/examples/performance/Performance";
import OrderHistory from "views/examples/orderHistory/OrderHistory";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },

  {
    path: "/restaurantinfo",
    name: "Restaurant Info",
    icon: "fas fa-building",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/menu",
    name: "Menu",
    icon: "fas fa-building",
    component: Menu,
    layout: "/admin",
  },

  {
    path: "/performance",
    name: "Performance",
    icon: "fas fa-building",
    component: Performance,
    layout: "/admin",
  },

  {
    path: "/order-history",
    name: "Order History",
    icon: "fas fa-building",
    component: OrderHistory,
    layout: "/admin",
  },
];
export default routes;
