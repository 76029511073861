import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Provider } from "react-redux";
import { Fallback } from "./components/Fallback";
import { store } from "./store/store";
import App from "App";

const history = createBrowserHistory();

const theme = createTheme();

Sentry.init({
  dsn: "https://828f2d25b3b145a7bc8a5a227e425dfe@o949194.ingest.sentry.io/6405477",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  environment:
    process.env.NODE_ENV === "production" ? "production" : "development",

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance
  // monitoring. We may want to adjust this in production if we start exceeding
  // our Sentry quota
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={Fallback} showDialog>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
